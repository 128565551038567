import React, { useEffect, useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [changePw, setChangePw] = useState(false);
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetch("https://prosjekt.tkva.no/api/profile.php", {
          credentials: "include",
        });
        const data = await res.json();
        setData(data);
      } catch (err) {
        return;
      }
    };
    getData();
  }, []);

  const editPw = async () => {
    if (pw1 === pw2) {
      if (pw1.length < 6) {
        alert("Passordet må bestå av flere enn 5 tegn.");
      } else {
        const postData = {
          action: "EDIT_PW",
          pw: pw1,
        };
        try {
          const res = await fetch("https://prosjekt.tkva.no/api/profile.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
            credentials: "include",
          });
        } catch (err) {
          alert("Noe gikk galt..");
        }

        setChangePw(false);
        alert("Passordet ditt er endret");
        navigate("/login");
      }
    } else {
      alert("Passordene er ikke like");
    }
  };

  return (
    <>
      {!data.length > 0 ? (
        <Spinner />
      ) : (
        <>
          <h5>{data?.[0]?.name}</h5>
          <hr />
          <div className="d-flex">
            <div className="p-2">
              <b>Tilhørighet</b>
            </div>
            <div className="p-2">
              <span>{data?.[0]?.type_name}</span>
            </div>
          </div>
          <div className="d-flex">
            <div className="p-2">
              <b>E-post</b>
            </div>
            <div className="p-2">
              <span>{data?.[0]?.email}</span>
            </div>
          </div>
          {!changePw && (
            <>
              <div className="d-flex">
                <div className="p-2">
                  <b>Passord</b>
                </div>
                <div className="p-2">
                  <span>
                    <i>Skjult</i>
                  </span>
                </div>
              </div>
              <Button size="sm" onClick={() => setChangePw(true)}>
                Endre passord
              </Button>
            </>
          )}
          {changePw && (
            <>
              <hr />
              <Alert variant="danger">
                <h6>Endre passord</h6>
                <hr />
                <div className="d-flex align-items-center">
                  <div className="p-2" style={{ width: 185 }}>
                    Passord (*)
                  </div>
                  <div className="p-2">
                    <input
                      type="password"
                      onChange={(e) => setPw1(e.target.value)}
                      style={{
                        borderRadius: 5,
                        backgroundColor: "lightgrey",
                        width: 225,
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="p-2" style={{ width: 185 }}>
                    Passord igjen (*)
                  </div>
                  <div className="p-2">
                    <input
                      type="password"
                      onChange={(e) => setPw2(e.target.value)}
                      style={{
                        borderRadius: 5,
                        backgroundColor: "lightgrey",
                        width: 225,
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "420px",
                  }}
                >
                  <PasswordStrengthBar password={pw1} minLength={6} />
                </div>
                <Button variant="danger" size="sm" onClick={() => editPw()}>
                  Lagre passord
                </Button>
                <Button
                  variant="warning"
                  onClick={() => setChangePw(false)}
                  size="sm"
                  style={{ float: "right" }}
                >
                  Avbryt
                </Button>
              </Alert>
            </>
          )}
        </>
      )}
    </>
  );
}
