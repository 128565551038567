import React, { useState } from "react";
import { Breadcrumb, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const ImportForbruk = () => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const onFileChange = (event) => {
    // Update the state
    setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  // On file upload (click the upload button)
  const onFileUpload = async () => {
    if (file) {
      try {
        setIsUploading(true);

        // Create a new FormData object
        const formData = new FormData();
        // Append the file to FormData
        formData.append("myFile", file, file.name);

        // Send a POST request using fetch
        const response = await fetch(
          "https://prosjekt.tkva.no/api/admin/import.php",
          {
            method: "POST",
            body: formData, // formData will automatically set the content-type to multipart/form-data
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        //const data = await response.json();
        //console.log("File uploaded successfully", data);
        alert("Opplastning fullført");
      } catch (error) {
        console.error("Error uploading the file", error);
      } finally {
        setIsUploading(false);
      }
    } else {
      console.warn("No file selected");
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to="../admin/import/forbruk">Importer forbruk</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex flex-column">
        <div className="p-2">
          <h5>Importer forbruk</h5>
          <span className="muted">
            Dette er skjema til bruk for å importere forbruksdata
          </span>
        </div>
        <div className="p-2 d-flex flex-column">
          <div>
            <Link
              to="https://prosjekt.tkva.no/api/admin/forbrukMal.xlsx"
              target="_blank"
            >
              <Button>Last ned Excel-skjema</Button>
            </Link>
            <hr />
          </div>
          <div className="p-2">
            <h6>Importer Excel-skjema (forbruk)</h6>
            <div className="form-input">
              <input onChange={onFileChange} type="file" />
              <Button disabled={isUploading} onClick={() => onFileUpload()}>
                Last opp
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ImportForbruk };
