import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function AdminLogs() {
  const [data, setData] = useState([]);
  const [updated, setUpdated] = useState("");

  // Expanded
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    const updateData = setInterval(() => getData(), 30000);
    getData();

    return () => {
      clearInterval(updateData);
    };
  }, []);

  const getData = async () => {
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/admin/logs.php", {
        credentials: "include",
      });
      const data = await res.json();
      if (data) {
        const initialExpanded = {};
        data.forEach((_, logKey) => {
          initialExpanded[logKey] = {};
        });
        setExpanded(initialExpanded);
      }
      setData(data);
      setUpdated(new Date().toLocaleTimeString());
    } catch (err) {
      return;
    }
  };

  const toggleExpand = (logKey, logInfoKey) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [logKey]: {
        ...prevExpanded[logKey],
        [logInfoKey]: !prevExpanded[logKey][logInfoKey], // Toggler visning
      },
    }));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to="../admin/logs">Logg</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col col-md-12 col-lg-10 col-sm">
          <div className="position-relative">
            <div className="d-flex justify-content-between">
              <h4>Logg</h4>
              <span style={{ cursor: "pointer" }} onClick={() => getData()}>
                Sist oppdatert {updated}
              </span>
            </div>

            {data?.map((log, logKey) => {
              return (
                <div className="card mb-2" key={logKey}>
                  <div className="card-header">
                    {log.logTime} | {log.table_name}
                  </div>
                  <div className="card-body">
                    {log?.data?.map((logInfo, logInfoKey) => {
                      const isExpanded = expanded[logKey]?.[logInfoKey];
                      return (
                        <div className="card mb-2" key={logInfoKey}>
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <h5 className="card-title">
                                {logInfo.operation_type}
                              </h5>
                              <i
                                className={
                                  isExpanded
                                    ? "bi bi-arrow-up"
                                    : "bi bi-arrow-down"
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleExpand(logKey, logInfoKey)}
                              />
                            </div>
                            <div
                              className={`d-flex flex-column justify-content-between ${
                                isExpanded ? "" : "d-none"
                              }`}
                            >
                              <div className="p-2 w-100">
                                <b>Old Data:</b>

                                <DataTable
                                  data={JSON.parse(logInfo.old_data)}
                                />
                              </div>
                              <div className="p-2 w-1000">
                                <b>New Data:</b>
                                <DataTable
                                  data={JSON.parse(logInfo.new_data)}
                                />
                              </div>
                            </div>
                            <hr />
                            <b>Utført av {logInfo.changed_by}</b>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

const DataTable = ({ data = {} }) => {
  // Sjekk om data er definert
  if (!data || Object.keys(data).length === 0) {
    return <p>Ingen data tilgjengelig</p>;
  }

  // Hent alle nøklene fra JSON-objektet for å lage tabellens header
  const keys = Object.keys(data);

  return (
    <div className="table table-responsive">
      <table className="table" border="1">
        <thead>
          <tr>
            {keys.map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {keys.map((key) => (
              <td key={key}>{data[key]}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
