import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NoFoundPage() {
  const navigate = useNavigate();
  return (
    <>
      <h4>Siden finnes ikke...</h4>
      <div className="p-2">
        <Button
          variant="warning"
          onClick={() => {
            navigate(-1);
          }}
        >
          Gå tilbake til forrige side
        </Button>
      </div>
    </>
  );
}
