import React, { useEffect, useState } from "react";
import {
  Spinner,
  Table,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

export default function NewProject() {
  const navigate = useNavigate();
  // Error handler
  const [loaded, setLoaded] = useState(false);
  const [err, setErr] = useState(false);

  // Modal Info
  const [lines, setLines] = useState(1);

  // Data
  const [info, setInfo] = useState([]);
  const [projects, setProjects] = useState([]);

  // Prosjektdetaljer
  const [type, setType] = useState(""); // vann og avløp, veg etc.
  const [pnr, setPnr] = useState(""); // Prosjektnr
  const [pname, setPname] = useState(""); // Prosjektnavn
  const [parent, setParent] = useState(""); // Parent
  const [economy, setEconomy] = useState([]); // Økonomi og tjeneste i arrays

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/admin/project.php?new",
          { credentials: "include" }
        );
        const data = await res.json();

        setInfo(data.data);
        setLoaded(true);

        let proj = [];
        for (let i = 0; i < data.projects.length; i++) {
          proj.push(data.projects[i].pnr);
        }
        setProjects(proj);
      } catch (err) {
        setLoaded(true);
        setErr(err.message);
      }
    };

    getData();

    return () => {
      setLines(1);
      setInfo([]);
      setProjects([]);
      setType("");
      setPnr("");
      setPname("");
      setParent("");
      setEconomy([]);
      setLoaded(false);
      setErr(false);
    };
  }, []);

  const saveProject = async () => {
    const postData = {
      action: "new",
      type: type,
      pnr: pnr,
      pname: pname,
      parent: parent,
      data: economy,
    };

    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/project.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );

      if (res.ok) {
        navigate("/admin/project/edit/" + pnr);
      }
      //const result = await res.json();
      //console.log("Response fra server:", result);
    } catch (err) {
      alert("Noe gikk galt\n" + err.message);
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to="../admin/project/new">Nytt prosjekt</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {loaded ? (
        err ? (
          <span className="danger">Noe gikk galt: {err} </span>
        ) : (
          <>
            <div className="row">
              <div className="col h-100">
                <h4>Type prosjekt</h4>
                <div className="content">
                  {type ? (
                    <select
                      className="form-select"
                      disabled
                      style={{ width: "100%" }}
                    >
                      <option>{type}</option>
                    </select>
                  ) : (
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setType(e.target.value)}
                      disabled={type && true}
                      value={"Velg type"}
                    >
                      <option>Velg type</option>
                      {info.map((i, index) => {
                        return (
                          <option key={index} value={i.name}>
                            {i.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </div>
              </div>
              <div className="col h-100">
                <h4>Versjon</h4>
                <div className="content">
                  {info.find((item) => item.name === type)?.version ||
                    "Versjon ikke funnet"}
                </div>
              </div>
              <div className="col h-100">
                <h4>Årstall</h4>
                <div className="content">
                  {info.find((item) => item.name === type)?.year ||
                    "Årstall ikke funnet"}
                </div>
              </div>
            </div>
            {type && (
              <div className="row my-2">
                <div className="col-4 h-100">
                  <h4>Prosjektnr</h4>
                  <div className="content">
                    <input
                      type="text"
                      placeholder="Fyll inn prosjektnr"
                      onChange={(e) => setPnr(e.target.value)}
                      autoFocus
                      style={{ border: 0, width: "100%", padding: 10 }}
                    />
                  </div>
                </div>
                <div className="col-4 h-100">
                  <h4>Prosjektnavn</h4>
                  <div className="content">
                    <input
                      type="text"
                      placeholder="Fyll inn prosjektnavn"
                      onChange={(e) => setPname(e.target.value)}
                      style={{ border: 0, width: "100%", padding: 10 }}
                    />
                  </div>
                </div>
                <div className="col-4 h-100">
                  <h4>Hovedprosjekt</h4>
                  <div className="content">
                    <input
                      type="text"
                      placeholder="Fyll inn prosjektnavn"
                      onChange={(e) => setParent(e.target.value)}
                      style={{ border: 0, width: "100%", padding: 10 }}
                    />
                  </div>
                </div>
              </div>
            )}

            {projects.includes(pnr) ? (
              <div className="alert alert-danger">
                <b>Prosjektet finnes allerede!!!</b>
              </div>
            ) : (
              <>
                {pnr && pname && (
                  <div className="row my-2">
                    <div className="col">
                      <h4>Økonomiplan</h4>
                      <div className="table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th>Tjeneste</th>
                              {Array.from({ length: 10 }, (_, index) => {
                                return (
                                  <th key={index} className="text-center">
                                    {parseInt(
                                      info.find((item) => item.name === type)
                                        ?.year
                                    ) + index}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {Array.from({ length: lines }, (line, lIndex) => {
                              return (
                                <tr key={lIndex}>
                                  <td>
                                    <input
                                      type="text"
                                      placeholder="Tjeneste"
                                      style={{ width: "75%" }}
                                      onChange={(e) =>
                                        setEconomy((prev) => {
                                          const newEconomy = [...prev];
                                          const updItem = {
                                            ...newEconomy[lIndex],
                                            tjeneste: e.target.value,
                                          };
                                          newEconomy[lIndex] = updItem;
                                          return newEconomy;
                                        })
                                      }
                                    />
                                  </td>
                                  {Array.from({ length: 10 }, (_, index) => {
                                    return (
                                      <td key={index} className="text-center">
                                        <input
                                          type="number"
                                          onChange={(e) =>
                                            setEconomy((prev) => {
                                              const eTitle =
                                                "e" +
                                                parseInt(
                                                  parseInt(index) + parseInt(1)
                                                );
                                              const newEconomy = [...prev];
                                              const updItem = {
                                                ...newEconomy[lIndex],
                                                [eTitle]: e.target.value,
                                              };
                                              newEconomy[lIndex] = updItem;
                                              return newEconomy;
                                            })
                                          }
                                          defaultValue={0}
                                          style={{
                                            width: "60%",
                                            textAlign: "center",
                                          }}
                                        />
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex justify-content-end"
                          style={{
                            backgroundColor: "#fafafa",
                            padding: 10,
                            borderRadius: 10,
                          }}
                        >
                          {lines > 1 && (
                            <Button
                              size="sm"
                              variant="danger"
                              style={{ marginTop: 0, marginRight: 5 }}
                              onClick={() => {
                                setLines((prev) => prev - 1);
                                setEconomy((prev) => prev.slice(0, -1));
                              }}
                            >
                              Fjern siste linje
                            </Button>
                          )}
                          <Button
                            size="sm"
                            variant="info"
                            style={{ marginTop: 0, marginRight: 5 }}
                            onClick={() => setLines((prev) => prev + 1)}
                          >
                            Legg til ny linje
                          </Button>
                          <Button
                            size="sm"
                            variant="success"
                            onClick={() => saveProject()}
                          >
                            Lagre prosjekt
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )
      ) : (
        <Spinner />
      )}
    </>
  );
}
