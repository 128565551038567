import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Modal,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";

const convertNumber = (number) => {
  let num = parseInt(number);
  if (isNaN(num)) {
    return number;
  }
  return num.toLocaleString();
};
const convertDateFormat = (dateStr) => {
  // Split the date and time parts
  const [datePart, timePart] = dateStr.split(" ");

  // Split the date part into year, month, and day
  const [year, month, day] = datePart.split("-");

  // Create the new formatted date
  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
};

export default function ProjectHistory({ project, children }) {
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [pname, setPname] = useState("");

  const [data, setData] = useState([]);

  // Table
  const [bigTable, setBigTable] = useState(false);

  useEffect(() => {
    if (show) {
      getData(project);
    }

    return () => {
      setLoaded(false);
      setPname("");
      setData([]);
    };
  }, [show]);

  const getData = async (pnr) => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/ProjectHistory.php?pnr=" + pnr,
        { credentials: "include" }
      );
      const data = await res.json();
      setData(data);
      setPname(data[0]["data"][0].pname);
      setLoaded(true);
    } catch (err) {
      return;
    }
  };

  const _Child = () => {
    return <div onClick={() => setShow(true)}>{children}</div>;
  };

  return (
    <>
      <_Child />
      <Modal
        size="xl"
        show={show}
        onHide={() => setShow(false)}
        className="history_p_modal"
      >
        {loaded ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                Historikk {project} - {pname}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 m-0">
              <div className="container-fluid m-0 p-0">
                {data.map((d, dKey) => {
                  return (
                    <div className="historyRow" key={dKey}>
                      <div className="historyInfo">
                        <div className="d-flex justify-content-between p-2">
                          <div>Regnskapsår {d.year}</div>
                          <div>
                            <Button
                              size="sm"
                              onClick={() => setBigTable((prev) => !prev)}
                            >
                              Full tabell
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="historyTable m-0 p-2 table-responsive"
                        style={{ maxHeight: "60vh", overflowY: "auto" }}
                      >
                        <Table striped hover variant="light" className="m-0">
                          <thead
                            style={{ position: "sticky", top: -15, zIndex: 2 }}
                          >
                            <tr>
                              <th>Versjon</th>
                              <th>{d.year}</th>
                              <th>{parseInt(d.year) + 1}</th>
                              <th>{parseInt(d.year) + 2}</th>
                              <th>{parseInt(d.year) + 3}</th>
                              {bigTable && (
                                <>
                                  <th>{parseInt(d.year) + 4}</th>
                                  <th>{parseInt(d.year) + 5}</th>
                                  <th>{parseInt(d.year) + 6}</th>
                                  <th>{parseInt(d.year) + 7}</th>
                                  <th>{parseInt(d.year) + 8}</th>
                                  <th>{parseInt(d.year) + 9}</th>
                                </>
                              )}
                              <th style={{ width: "20%" }}>
                                <i className="bi bi-chat-left-dots" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {d.data?.map((ec, ecKey) => {
                              return (
                                <tr key={ecKey}>
                                  <td>
                                    {ec.version}. {ec.versionComment} <br />
                                    <span className="text-muted">
                                      {ec.user}
                                    </span>
                                    <br />
                                    <span className="text-muted">
                                      {convertDateFormat(ec.updated)}
                                    </span>
                                  </td>
                                  <td>{convertNumber(ec.e1)}</td>
                                  <td>{convertNumber(ec.e2)}</td>
                                  <td>{convertNumber(ec.e3)}</td>
                                  <td>{convertNumber(ec.e4)}</td>
                                  {bigTable && (
                                    <>
                                      <td>{convertNumber(ec.e5)}</td>
                                      <td>{convertNumber(ec.e6)}</td>
                                      <td>{convertNumber(ec.e7)}</td>
                                      <td>{convertNumber(ec.e8)}</td>
                                      <td>{convertNumber(ec.e9)}</td>
                                      <td>{convertNumber(ec.e10)}</td>
                                    </>
                                  )}
                                  <td>
                                    <ShortComment comment={ec.comment} />
                                  </td>
                                  <td className="d-none">
                                    {ec.updated && (
                                      <ButtonToolbar>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="tooltip">
                                              {ec.user && (
                                                <>
                                                  {ec.user} <br />{" "}
                                                </>
                                              )}
                                              {ec.updated}
                                            </Tooltip>
                                          }
                                        >
                                          <i
                                            className="bi bi-clock"
                                            style={{ cursor: "pointer" }}
                                          />
                                        </OverlayTrigger>
                                      </ButtonToolbar>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Modal.Body>
          </>
        ) : (
          <div className="container my-4">
            <center>
              <Spinner />
            </center>
          </div>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Lukk
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const ShortComment = ({ comment }) => {
  const [show, setShow] = useState(false);

  return comment?.length > 115 && !show ? (
    <>
      <span>{comment.slice(0, 115)}...</span>
      <br />
      <span
        style={{ cursor: "pointer", float: "right" }}
        onClick={() => setShow(true)}
        className="text-muted"
      >
        Les mer...
      </span>
    </>
  ) : (
    <>
      <span>{comment}</span>
      {show && (
        <>
          <br />
          <span
            style={{ cursor: "pointer", float: "right" }}
            onClick={() => setShow(false)}
            className="text-muted"
          >
            Skjul...
          </span>
        </>
      )}
    </>
  );
};
