import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function PL() {
  const [pl, setPl] = useState([]);
  const [plMissing, setPlMissing] = useState([]);
  const [hasPl, setHasPl] = useState([]);

  // Add pleader
  const [pickedProj, setPickedProj] = useState(null);

  // Expand projectleader
  const [expanedUsers, setExpandedUsers] = useState([]);

  useEffect(() => {
    getPL();
    getPlMissing();
    getHasPl();

    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setPickedProj(null);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const getPL = async () => {
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/admin/pl.php?pl", {
        credentials: "include",
      });
      const data = await res.json();
      setPl(data);
    } catch (err) {
      return;
    }
  };
  const getPlMissing = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/pl.php?plMissing",
        { credentials: "include" }
      );
      const data = await res.json();
      setPlMissing(data);
    } catch (err) {
      return;
    }
  };
  const getHasPl = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/pl.php?hasPl",
        { credentials: "include" }
      );
      const data = await res.json();
      setHasPl(data);
    } catch (err) {
      return;
    }
  };

  const addPL = async (userId, name) => {
    setPickedProj(null);
    const postData = {
      action: "addPL",
      userId: userId,
      name: name,
      pnr: pickedProj,
    };
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/admin/pl.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
        credentials: "include",
      });
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        getPL();
        getPlMissing();
        getHasPl();
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
  };

  return (
    <div className="row">
      <div className="col-8">
        {plMissing && (
          <>
            <h5>Prosjekter uten prosjektleder ({plMissing.length})</h5>
            <div
              className="content"
              style={{ maxHeight: "40vh", overflowY: "auto" }}
            >
              {plMissing.map((p, pIndex) => {
                return (
                  <div
                    className="col m-2"
                    key={pIndex}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "lightgrey",
                      padding: 5,
                      ...(pickedProj && { filter: "blur(1px)" }),
                    }}
                    onClick={() => setPickedProj(p.pnr)}
                  >
                    {p.pnr} - {p.pname}
                  </div>
                );
              })}
            </div>
          </>
        )}
        <div className="m-4" />
        <h5>Prosjekter med prosjektleder ({hasPl.length})</h5>
        <div
          className="content"
          style={{ maxHeight: "40vh", overflowY: "auto" }}
        >
          {hasPl.map((p, pIndex) => {
            return (
              <div className="col m-2" key={pIndex}>
                {p.pnr} - {p.pname}
              </div>
            );
          })}
        </div>
      </div>
      <div className="col-3">
        <h6>Brukere</h6>
        {pl.map((p, pIndex) => {
          return (
            <div
              className="d-flex flex-column"
              key={pIndex}
              style={{
                borderBottom: "1px #000 solid",
                paddingBottom: 5,
                marginBottom: 5,
              }}
            >
              <div className="d-flex">
                <div className="p-2">
                  <i
                    className="bi bi-person-badge"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setExpandedUsers((prev) => {
                        if (prev.includes(p.name)) {
                          return prev.filter((name) => name !== p.name);
                        } else {
                          return [...prev, p.name];
                        }
                      });
                    }}
                  />
                </div>
                <div className="p-2 d-flex flex-row justify-content-between w-100">
                  <Link to={"/admin/users/" + p.id}>{p.name}</Link>
                  {pickedProj && (
                    <div>
                      <i
                        className="bi bi-plus-circle-dotted"
                        style={{ cursor: "pointer" }}
                        onClick={() => addPL(p.id, p.name)}
                      />
                    </div>
                  )}
                </div>
              </div>
              {p.proj.length > 0 && (
                <div
                  className={`d-flex ${
                    !expanedUsers.includes(p.name) && "d-none"
                  }`}
                  style={{ backgroundColor: "lightgrey", borderRadius: 2 }}
                >
                  <div className="p-2">
                    <i className="bi bi-buildings" />
                  </div>
                  <div className="p-2 text-dark">
                    {p.proj.map((proj, projIndex) => {
                      return (
                        <span key={projIndex}>
                          <Link to={"/admin/project/edit/" + proj.pnr}>
                            {proj.pnr} - {proj.pname}
                          </Link>
                          ,{" "}
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
