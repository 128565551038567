import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import html2pdf from "html2pdf.js";

const convertNumber = (number) => {
  let num = parseInt(number);
  if (isNaN(num)) {
    return number;
  }
  return num.toLocaleString();
};

export default function Document() {
  const printRef = useRef();
  const [step, setStep] = useState(0);

  const [type, setType] = useState(null);
  const [types, setTypes] = useState([]);

  const handlePrintPdf = () => {
    const element = printRef.current;

    html2pdf()
      .set({
        margin: [10, 0, 10, 0], // Økt topp- og bunnmargin for å unngå kutting
        filename: "report.pdf",
        image: { type: "jpeg", quality: 1 }, // Høyeste kvalitet for bilder
        pagebreak: {
          avoid: ["tr", "td", "div"],
        },
        html2canvas: {
          dpi: 300, // Øk DPI for bedre kvalitet
          scale: 3, // Øk skalaen for bedre oppløsning
          letterRendering: true,
          useCORS: true,
          scrollY: 0, // Unngår problemer med rulling i lange dokumenter
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      })
      .from(element)
      .save();
  };

  // Step 0
  const [typeData, setTypeData] = useState([]);

  // Current Version - Report
  const [reportYear, setReportYear] = useState(null);
  const [reportVersion, setReportVersion] = useState(null);
  const [reportName, setReportName] = useState(null);

  const getRepData = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/history.php?avviksanalyse&getType=" +
          type,
        { credentials: "include" }
      );
      const data = await res.json();
      setTypeData(data);
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    if (type) {
      getRepData();
    }
  }, [type]);

  // Step 1
  const [parameters, setParameters] = useState([]);

  // Step 1 - Status
  const [statusData, setStatusData] = useState([]);
  const getStatData = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/history.php?historikk=" +
          type +
          "&year=" +
          reportYear +
          "&version=" +
          reportVersion,
        { credentials: "include" }
      );
      const data = await res.json();
      setStatusData(data);
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    if (
      step === 2 &&
      parameters.length === 1 &&
      parameters.includes("status")
    ) {
      setStep(3);
    }
  }, [step, parameters]);

  // Step 2 - Avvik
  const [avvikData, setAvvikData] = useState([]);
  const [avvikYear, setAvvikYear] = useState(null);
  const [avvikVersion, setAvvikVersion] = useState(null);
  const [avvikName, setAvvikName] = useState(null);

  const getAvData = async (mainOnly = false) => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/history.php?avviksanalyse&runType=" +
          type +
          "&fromYear=" +
          avvikYear +
          "&fromVersion=" +
          avvikVersion +
          "&toYear=" +
          reportYear +
          "&toVersion=" +
          reportVersion +
          "&mainOnly=" +
          mainOnly,
        { credentials: "include" }
      );
      const data = await res.json();
      setAvvikData(data);
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    if (parameters.includes("avvik") && avvikName && avvikVersion && avvikYear)
      getAvData();
  }, [parameters, avvikName, avvikVersion, avvikYear]);

  useEffect(() => {
    if (type && reportVersion && reportVersion) {
      getStatData();
    }
  }, [reportYear, reportVersion, type]);

  // Step 2 - Rapport per prosjekt
  const [dataPrProj, setDataPrProj] = useState([]);
  const getRepProjData = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/history.php?reportPrProj=" +
          type +
          "&year=" +
          reportYear +
          "&version=" +
          reportVersion,
        { credentials: "include" }
      );
      const data = await res.json();
      setDataPrProj(data);
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    if (parameters.includes("report")) {
      getRepProjData();
    }
  }, [parameters]);

  // Step 2 Parameter details
  const [summary, setSummary] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/history.php?getTypes",
          { credentials: "include" }
        );
        const data = await res.json();

        setTypes(data);
      } catch (err) {
        return;
      }
    };
    getData();
  }, []);

  return type ? (
    <>
      <div className="d-flex">
        <div>
          <h5>Eksport av dokument</h5>
        </div>
        <div className="ms-auto p-2 bd-highlight">
          Du har valgt: {type} &nbsp;
          <Button size="sm" variant="secondary" onClick={() => setType(null)}>
            Fjern type
          </Button>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => setStep((p) => p - 1)}
          >
            {"<-"}
          </Button>
        </div>
      </div>

      {step === 0 && (
        <div>
          <hr />
          <h6>Velg rapport</h6>
          <select
            className="form-select"
            onChange={(e) => {
              const selectedOption = e.target.options[e.target.selectedIndex];
              const year = selectedOption.getAttribute("data-year");
              const version = selectedOption.getAttribute("data-version");
              const name = selectedOption.getAttribute("data-name");
              setReportYear(year);
              setReportVersion(version);
              setReportName(name);
            }}
            style={{ maxWidth: "400px" }}
          >
            <option selected disabled>
              Velg rapport
            </option>
            {typeData?.map((type, typeKey) => {
              return (
                <option
                  data-year={type.year}
                  data-version={type.version}
                  data-name={type.comment}
                  key={typeKey}
                >
                  {type.year} - {type.comment}
                </option>
              );
            })}
          </select>
          {reportName && (
            <Button className="mt-2" onClick={() => setStep(1)}>
              Gå videre
            </Button>
          )}
        </div>
      )}

      {step === 1 && (
        <div>
          <hr />
          <h6>Velg parameter</h6>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="flexCheckDefault"
              onChange={(e) => {
                const thisType = "summary";
                setParameters((prev) => {
                  if (e.target.checked) {
                    // Legg til 'thisType' hvis den ikke finnes
                    return prev.includes(thisType) ? prev : [...prev, thisType];
                  } else {
                    // Fjern 'thisType' hvis den finnes
                    return prev.filter((item) => item !== thisType);
                  }
                });
              }}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Oppsummering av rapport
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="flexCheckChecked"
              defaultChecked=""
              onChange={(e) => {
                const thisType = "status";
                setParameters((prev) => {
                  if (e.target.checked) {
                    // Legg til 'thisType' hvis den ikke finnes
                    return prev.includes(thisType) ? prev : [...prev, thisType];
                  } else {
                    // Fjern 'thisType' hvis den finnes
                    return prev.filter((item) => item !== thisType);
                  }
                });
              }}
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              Status for denne rapport
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="avvikCheck"
              defaultChecked=""
              onChange={(e) => {
                const thisType = "avvik";
                setParameters((prev) => {
                  if (e.target.checked) {
                    // Legg til 'thisType' hvis den ikke finnes
                    return prev.includes(thisType) ? prev : [...prev, thisType];
                  } else {
                    // Fjern 'thisType' hvis den finnes
                    return prev.filter((item) => item !== thisType);
                  }
                });
              }}
            />
            <label className="form-check-label" htmlFor="avvikCheck">
              Avviksrapport
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue=""
              id="reportCheck"
              defaultChecked=""
              onChange={(e) => {
                const thisType = "report";
                setParameters((prev) => {
                  if (e.target.checked) {
                    // Legg til 'thisType' hvis den ikke finnes
                    return prev.includes(thisType) ? prev : [...prev, thisType];
                  } else {
                    // Fjern 'thisType' hvis den finnes
                    return prev.filter((item) => item !== thisType);
                  }
                });
              }}
            />
            <label className="form-check-label" htmlFor="reportCheck">
              Rapport pr. prosjekt
            </label>
          </div>
          <hr />
          <Button onClick={() => setStep(2)}>Gå videre</Button>
        </div>
      )}

      {step === 2 && (
        <div>
          <hr />
          {parameters.includes("summary") && (
            <>
              <h6>Oppsummering</h6>
              <textarea
                style={{
                  width: "100%",
                  maxWidth: "1260px",
                  minHeight: "240px",
                  borderRadius: 5,
                  padding: 5,
                }}
                placeholder="Skriv inn en oppsummering her"
                onChange={(e) => setSummary(e.target.value)}
              />
              <hr />
            </>
          )}
          {parameters.includes("avvik") && (
            <>
              <h6>Velg rapport å sammenligne med (avvik)</h6>
              <select
                className="form-select"
                onChange={(e) => {
                  const selectedOption =
                    e.target.options[e.target.selectedIndex];
                  const year = selectedOption.getAttribute("data-year");
                  const version = selectedOption.getAttribute("data-version");
                  const name = selectedOption.getAttribute("data-name");
                  setAvvikYear(year);
                  setAvvikVersion(version);
                  setAvvikName(name);
                }}
                style={{ maxWidth: "400px" }}
              >
                <option selected disabled>
                  Velg rapport
                </option>
                {typeData?.map((type, typeKey) => {
                  return (
                    <option
                      data-year={type.year}
                      data-version={type.version}
                      data-name={type.comment}
                      key={typeKey}
                    >
                      {type.year} - {type.comment}
                    </option>
                  );
                })}
              </select>
            </>
          )}
          <hr />
          <Button onClick={() => setStep(3)}>Kjør rapport</Button>
        </div>
      )}

      {step === 3 && (
        <>
          <hr />
          <div ref={printRef}>
            <div style={{ margin: 15, padding: 15, backgroundColor: "white" }}>
              <h2>{type}</h2>
              <h5>
                {reportName} - {reportYear}
              </h5>
              {summary && (
                <p>
                  {summary.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              )}
              <hr />
              {parameters.includes("status") && (
                <>
                  <h5>Økonomistatus for denne rapporten</h5>
                  {statusData?.map((d, k) => {
                    return (
                      <React.Fragment key={k}>
                        <div>
                          <Table bordered>
                            <thead>
                              <tr>
                                {Array.from({ length: 10 }, (_, index) => {
                                  return (
                                    <th
                                      key={index}
                                      style={{
                                        textAlign: "center",
                                        width: "8,5%",
                                        backgroundColor: "#fafafa",
                                      }}
                                    >
                                      {parseInt(d.year) + index}
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {d?.data?.map((dd, kk) => {
                                return (
                                  <React.Fragment key={kk}>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {convertNumber(dd.e1)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {convertNumber(dd.e2)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {convertNumber(dd.e3)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {convertNumber(dd.e4)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {convertNumber(dd.e5)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {convertNumber(dd.e6)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {convertNumber(dd.e7)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {convertNumber(dd.e8)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {convertNumber(dd.e9)}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {convertNumber(dd.e10)}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </>
              )}
              {parameters.includes("avvik") && (
                <>
                  <hr />

                  <h5>
                    Avviksanalyse - {avvikName} // {reportName}
                  </h5>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th
                          style={{
                            backgroundColor: "#fafafa",
                          }}
                        >
                          Prosjekt
                        </th>
                        <th
                          style={{
                            backgroundColor: "#fafafa",
                          }}
                        >
                          {avvikName}
                        </th>
                        <th
                          style={{
                            backgroundColor: "#fafafa",
                          }}
                        >
                          {reportName}
                        </th>
                        <th
                          style={{
                            backgroundColor: "#fafafa",
                          }}
                        >
                          Avvik
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {avvikData?.map((d, k) => {
                        return (
                          <tr
                            key={k}
                            style={
                              parseInt(d.avvik) === 0 ? { display: "none" } : {}
                            }
                          >
                            <td>
                              {d.pnr} - {d.pname}
                            </td>
                            <td>{convertNumber(d.e1)}</td>
                            <td>{convertNumber(d.e2)}</td>
                            <td>{convertNumber(d.avvik)}</td>
                          </tr>
                        );
                      })}
                      <tr>
                        <th>&nbsp;</th>
                        <th>
                          {convertNumber(
                            avvikData?.reduce(
                              (acc, av) => acc + parseInt(av.e1),
                              0
                            )
                          )}
                        </th>
                        <th>
                          {convertNumber(
                            avvikData?.reduce(
                              (acc, av) => acc + parseInt(av.e2),
                              0
                            )
                          )}
                        </th>
                        <th>
                          {convertNumber(
                            avvikData?.reduce(
                              (acc, av) => acc + parseInt(av.avvik),
                              0
                            )
                          )}
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
              {parameters.includes("report") && (
                <>
                  <h5>Rapport per prosjekt</h5>
                  {dataPrProj?.map((proj, pKey) => {
                    return (
                      <div
                        key={pKey}
                        className="p-4 mb-3 border-bottom bg-light"
                      >
                        <div className="d-flex">
                          <div>
                            <h6 className="">
                              {proj.pnr} - {proj.pname}
                            </h6>
                            <p>{proj.comment}</p>
                            <span>{proj.user}</span>
                          </div>
                          <div className="ms-auto">
                            <Table bordered style={{ minWidth: "400px" }}>
                              <thead>
                                <tr>
                                  <th>{reportYear}</th>
                                  <th>{parseInt(reportYear) + 1}</th>
                                  <th>{parseInt(reportYear) + 2}</th>
                                  <th>{parseInt(reportYear) + 3}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{convertNumber(proj.e1)}</td>
                                  <td>{convertNumber(proj.e2)}</td>
                                  <td>{convertNumber(proj.e3)}</td>
                                  <td>{convertNumber(proj.e4)}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
          <Button onClick={() => handlePrintPdf()}>Eksporter til PDF</Button>
        </>
      )}
    </>
  ) : (
    <>
      <div style={{ maxWidth: "1200px" }}>
        <Alert>Du må velge type før du kan gå videre...</Alert>
        <select
          className="form-select"
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          <option selected disabled>
            Velg prosjekttype
          </option>
          {types?.map((type, typeKey) => {
            return (
              <option key={typeKey} value={type.name}>
                {type.name}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
}

const Avviksanalyse = ({ type }) => {
  const [typeData, setTypeData] = useState([]);
  const [data, setData] = useState([]);

  // Options
  const [fromYear, setFromYear] = useState(null);
  const [fromVersion, setFromVersion] = useState(null);
  const [fromName, setFromName] = useState(null);
  const [toYear, setToYear] = useState(null);
  const [toVersion, setToVersion] = useState(null);
  const [toName, setToName] = useState(null);

  // Expanded Proj
  const [expandedProj, setExpandedProj] = useState(null);

  // Hoved eller underprosjetker
  const [onlyMainProjects, setOnlyMainProjects] = useState(false);

  useEffect(() => {
    const getTypeData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/history.php?avviksanalyse&getType=" +
            type,
          { credentials: "include" }
        );
        const data = await res.json();
        setTypeData(data);
      } catch (err) {
        return;
      }
    };
    getTypeData();
  }, []);

  const runReport = async (mainOnly = false) => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/history.php?avviksanalyse&runType=" +
          type +
          "&fromYear=" +
          fromYear +
          "&fromVersion=" +
          fromVersion +
          "&toYear=" +
          toYear +
          "&toVersion=" +
          toVersion +
          "&mainOnly=" +
          mainOnly,
        { credentials: "include" }
      );
      const data = await res.json();
      setData(data);
    } catch (err) {
      alert("Noe gikk galt...");
    }
  };

  const getTotal = (n) => {
    let total = 0;
    data.forEach((d) => {
      if (n === 1) {
        total += parseInt(d.e1);
      }
      if (n === 2) {
        total += parseInt(d.e2);
      }
    });

    return total;
  };

  return (
    <>
      <h6>Avviksanalyse for {type}</h6>
      {typeData.length <= 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <Spinner />
          </div>
        </div>
      )}

      {typeData.length > 0 && data.length <= 0 && (
        <>
          <div style={{ maxWidth: "1200px" }}>
            <div className="d-flex justify-content-center">
              <div className="p-2" style={{ minWidth: "600px" }}>
                <h6>1. Velg kolonne</h6>
                <div className="content">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      const selectedOption =
                        e.target.options[e.target.selectedIndex];
                      const year = selectedOption.getAttribute("data-year");
                      const version =
                        selectedOption.getAttribute("data-version");
                      const name = selectedOption.getAttribute("data-name");
                      setFromYear(year);
                      setFromVersion(version);
                      setFromName(name);
                    }}
                  >
                    <option selected disabled>
                      Velg kolonne
                    </option>
                    {typeData?.map((type, typeKey) => {
                      return (
                        <option
                          data-year={type.year}
                          data-version={type.version}
                          data-name={type.comment}
                          key={typeKey}
                        >
                          {type.year} - {type.comment}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="p-2" style={{ minWidth: "600px" }}>
                <h6>2. Velg kolonne</h6>
                <div className="content">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      const selectedOption =
                        e.target.options[e.target.selectedIndex];
                      const year = selectedOption.getAttribute("data-year");
                      const version =
                        selectedOption.getAttribute("data-version");
                      const name = selectedOption.getAttribute("data-name");
                      setToYear(year);
                      setToVersion(version);
                      setToName(name);
                    }}
                  >
                    <option selected disabled>
                      Velg kolonne
                    </option>
                    {typeData?.map((type, typeKey) => {
                      return (
                        <option
                          data-year={type.year}
                          data-version={type.version}
                          data-name={type.comment}
                          key={typeKey}
                        >
                          {type.year} - {type.comment}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end h-100">
              <div className="d-flex align-items-end">
                <Button onClick={() => runReport()}>Kjør rapport</Button>
              </div>
            </div>
          </div>
        </>
      )}

      {data?.length > 0 && (
        <>
          <div
            className="d-flex justify-content-end"
            style={{ maxWidth: "1200px" }}
          >
            <Button
              size="sm"
              onClick={() => {
                setOnlyMainProjects((prev) => {
                  runReport(!prev);
                  return !prev;
                });
              }}
              variant="primary"
              style={{ marginBottom: 5, marginRight: 5 }}
            >
              {onlyMainProjects
                ? "Vis alle prosjekter"
                : "Vis kun hovedprosjekter"}
            </Button>
            <Button
              size="sm"
              onClick={() => setData([])}
              variant="warning"
              style={{ marginBottom: 5 }}
            >
              Nullstill
            </Button>
          </div>
          <div
            className="table-responsive"
            style={{ maxWidth: "1200px", maxHeight: "80vh" }}
          >
            <Table>
              <thead>
                <tr style={{ position: "sticky", top: 0, zIndex: 2 }}>
                  <th>Prosjekt</th>
                  <th>{fromName}</th>
                  <th>{toName}</th>
                  <th>Avvik</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((d, k) => {
                  return (
                    <tr
                      key={k}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setExpandedProj((prev) => {
                          if (prev === d.pnr) {
                            return null;
                          }
                          return d.pnr;
                        })
                      }
                    >
                      <td
                        style={
                          expandedProj === d.pnr ? { fontWeight: "bold" } : {}
                        }
                      >
                        {d.pnr} - {d.pname}
                        {expandedProj === d.pnr && (
                          <>
                            <hr />
                            <div className="d-flex flex-column">
                              {d?.subData?.map((subD, subK) => {
                                return (
                                  <span key={subK} className="text-muted">
                                    {subD.tjeneste}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </td>
                      <td
                        style={
                          expandedProj === d.pnr ? { fontWeight: "bold" } : {}
                        }
                      >
                        {convertNumber(d.e1)}
                        {expandedProj === d.pnr && (
                          <>
                            <hr />
                            <div className="d-flex flex-column">
                              {d?.subData?.map((subD, subK) => {
                                return (
                                  <span key={subK} className="text-muted">
                                    {convertNumber(subD.e1)}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </td>
                      <td
                        style={
                          expandedProj === d.pnr ? { fontWeight: "bold" } : {}
                        }
                      >
                        {convertNumber(d.e2)}
                        {expandedProj === d.pnr && (
                          <>
                            <hr />
                            <div className="d-flex flex-column">
                              {d?.subData?.map((subD, subK) => {
                                return (
                                  <span key={subK} className="text-muted">
                                    {convertNumber(subD.e2)}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </td>
                      <td
                        style={
                          expandedProj === d.pnr ? { fontWeight: "bold" } : {}
                        }
                      >
                        {convertNumber(d.avvik)}
                        {expandedProj === d.pnr && (
                          <>
                            <hr />
                            <div className="d-flex flex-column">
                              {d?.subData?.map((subD, subK) => {
                                return (
                                  <span key={subK} className="text-muted">
                                    {convertNumber(subD.avvik)}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th>&nbsp;</th>
                  <th>{convertNumber(getTotal(1))}</th>
                  <th>{convertNumber(getTotal(2))}</th>
                  <th>{convertNumber(getTotal(2) - getTotal(1))}</th>
                  <th>&nbsp;</th>
                </tr>
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};
