import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";

export default function PowerBI() {
  const params = useParams();
  const [type, setType] = useState(null);

  useEffect(() => {
    console.log(params.type);
    if (params.type) {
      setType(params.type);
    } else {
      setType(null);
    }
  }, [params]);

  return !type ? (
    <>
      <h4>Velg rapport</h4>
      <div className="d-flex">
        <div className="p-2">
          <Link to="Vann og avløp">
            <Button>Vann og avløp</Button>
          </Link>
        </div>
        <div className="p-2">
          <Link to="Veg">
            <Button>Veg</Button>
          </Link>
        </div>
      </div>
    </>
  ) : (
    <>
      {type === "Vann og avløp" && (
        <iframe
          title="VA-Prosjektoversikt v2"
          width="100%"
          height="98%"
          src="https://app.powerbi.com/reportEmbed?reportId=e3ccd7fb-6417-452b-aa9c-8b7e8b263966&autoAuth=true&ctid=91f01968-b32d-4aa6-8d47-da1fdf0e5a59"
          frameBorder={0}
          allowFullScreen="true"
        />
      )}
      {type === "Veg" && (
        <iframe
          title="Prosjektoversikt VEG"
          width="100%"
          height="98%"
          src="https://app.powerbi.com/reportEmbed?reportId=65aa662d-b3ae-44f5-b685-c26c396de6bf&autoAuth=true&ctid=91f01968-b32d-4aa6-8d47-da1fdf0e5a59"
          frameBorder={0}
          allowFullScreen="true"
        />
      )}
    </>
  );
}
