import React, { useState, useEffect } from "react";
import { Alert, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export default function Overview() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const [error, setError] = useState(false);

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjects().then(() => {
      setLoaded(true);
    });

    return () => {
      setLoaded(false);
    };
  }, []);

  const getProjects = async () => {
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/overview.php", {
        credentials: "include",
      });
      const data = await res.json();
      if (data.proj.length < 1) {
        navigate("projects", { replace: true });
      }
      setProjects(data);
    } catch (err) {
      console.error(err);
      setError(err);
    }
  };

  return (
    <>
      {loaded ? (
        error ? (
          <Alert variant="danger">
            <b>Noe gikk galt.</b> Prøv igjen senere
          </Alert>
        ) : (
          <>
            <div className="row d-flex">
              <div className="col-xl-6 col-lg-8 my-2">
                <div
                  className="card h-100"
                  style={{
                    backgroundColor: "transparent",
                    border: 0,
                    borderRadius: 0,
                  }}
                >
                  <Table borderless hover>
                    <thead>
                      <tr
                        style={{
                          height: 50,
                        }}
                      >
                        <th
                          style={{
                            width: "50%",
                            color: "#344050",
                            backgroundColor: "#fafafa",
                            fontWeight: 500,
                            fontSize: 20,
                          }}
                        >
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/7604/7604036.png"
                            height={35}
                          />{" "}
                          &nbsp; Mine prosjekter
                        </th>
                        <th
                          style={{
                            width: "25%",
                            color: "#344050",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "#fafafa",
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            textAlign: "right",
                            color: "#344050",
                            fontWeight: 500,
                            fontSize: 20,
                            backgroundColor: "#fafafa",
                          }}
                        >
                          Sist oppdatert
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.proj?.map((p, pIndex) => {
                        return (
                          <tr
                            key={pIndex}
                            style={{
                              fontSize: 16,
                            }}
                          >
                            <td
                              style={{
                                backgroundColor: "#fff",
                              }}
                            >
                              {p.pnr} - {p.pname}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                              }}
                            >
                              {parseInt(p.reportOpen) ? (
                                <Link
                                  to={"reports/" + p.pnr}
                                  className="text-decoration-none"
                                >
                                  {parseInt(p.reportAccepted) ? (
                                    <>
                                      {parseInt(p.reportChanges) > 1 ? (
                                        <span className="text-success ">
                                          <i className="bi bi-check-circle" />{" "}
                                          Godkjent med endring
                                        </span>
                                      ) : (
                                        <span className="text-success ">
                                          <i className="bi bi-check-circle" />{" "}
                                          Godkjent
                                        </span>
                                      )}
                                    </>
                                  ) : parseInt(p.reportSent) ? (
                                    <span className="text-success ">
                                      <i className="bi bi-cloud-download-fill" />{" "}
                                      Rapportert
                                    </span>
                                  ) : parseInt(p.reportSaved) ? (
                                    <span style={{ color: "#B87333" }}>
                                      <i className="bi bi-save" /> Kladdet
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      <i className="bi bi-x-circle-fill" /> Ikke
                                      rapportert
                                    </span>
                                  )}
                                </Link>
                              ) : (
                                <span>
                                  <i className="bi bi-x-octagon" /> Ikke åpen
                                </span>
                              )}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fff",
                                textAlign: "right",
                              }}
                            >
                              {p.updated}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="col-xl-5 col-lg-4 my-2">
                <div className="card" style={{ borderRadius: 0, border: 0 }}>
                  <div
                    className="card-header d-flex flex-between-center py-2"
                    style={{
                      border: 0,
                      color: "white",
                      borderRadius: 0,
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/1786/1786640.png"
                      height={35}
                    />{" "}
                    &nbsp;
                    <h5
                      className="mb-0 py-2"
                      style={{
                        color: "#000 !important",
                      }}
                    >
                      Rapportering
                    </h5>
                  </div>
                  <div
                    className="card-body pb-0 text-dark"
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {projects.reports?.map((r, rIndex) => {
                      return (
                        <React.Fragment key={rIndex}>
                          <div className="d-flex mb-3 align-items-center">
                            <div style={{ width: 45 }}>
                              <img
                                className="border h-100 w-100 object-fit-cover rounded-2"
                                src={r.typeImg}
                                alt=""
                              />
                            </div>
                            <div className="ms-3 flex-shrink-1 flex-grow-1">
                              <h6 className="mb-1">{r.typeName}</h6>
                              <div className="fs-10">
                                <span className="fw-semi-bold">
                                  Rapportering:
                                </span>
                                <span className="fw-medium text-600 ms-2">
                                  {parseInt(r.reportOpen) ? (
                                    <>
                                      <span>Åpen</span> <br />
                                      <span>Rapporteringsfrist: </span>{" "}
                                      {r.endDate}
                                    </>
                                  ) : (
                                    "Stengt"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr className="text-200" />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}
