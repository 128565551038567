import React, { useEffect, useState, useMemo } from "react";
import {
  Alert,
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import ProjectHistory from "../projects/history";

const convertDateFormat = (dateStr) => {
  // Split the date and time parts
  const [datePart, timePart] = dateStr.split(" ");

  // Split the date part into year, month, and day
  const [year, month, day] = datePart.split("-");

  // Create the new formatted date
  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
};
const convertNumber = (number) => {
  let num = parseInt(number);
  if (isNaN(num)) {
    return number;
  }
  return num.toLocaleString();
};

export function History() {
  const [type, setType] = useState(null);
  const [types, setTypes] = useState([]);
  const [page, setPage] = useState(null);

  // Historikk
  // Temafordeling
  // Avviksanalyse
  // Ressursstyring
  // P360-versjon?

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/history.php?getTypes",
          { credentials: "include" }
        );
        const data = await res.json();

        setTypes(data);
      } catch (err) {
        return;
      }
    };
    getData();
  }, []);

  const RenderSwitch = ({ page }) => {
    switch (page) {
      case "Temafordeling":
        return <Temafordeling type={type} />;

      case "Avviksanalyse":
        return <Avviksanalyse type={type} />;

      case "Ressursstyring":
        return <Ressursstyring type={type} />;

      default:
        return <Historikk type={type} />;
    }
  };

  return type ? (
    <>
      <div className="d-flex">
        <Button className="m-2" size="sm" onClick={() => setPage(null)}>
          Historikk
        </Button>
        <Button
          className="m-2"
          size="sm"
          onClick={() => setPage("Temafordeling")}
        >
          Temafordeling
        </Button>
        <Button
          className="m-2"
          size="sm"
          onClick={() => setPage("Avviksanalyse")}
        >
          Avviksanalyse
        </Button>
        <Button
          className="m-2"
          size="sm"
          onClick={() => setPage("Ressursstyring")}
        >
          Ressursstyring
        </Button>
        <div className="ms-auto p-2 bd-highlight">
          Du har valgt: {type} &nbsp;
          <Button size="sm" variant="secondary" onClick={() => setType(null)}>
            Fjern type
          </Button>
        </div>
      </div>
      <h5>{page ? page : "Historikk"}</h5>
      <div>
        <RenderSwitch page={page} />
      </div>
    </>
  ) : (
    <>
      <div style={{ maxWidth: "1200px" }}>
        <Alert>Du må velge type før du kan gå videre...</Alert>
        <select
          className="form-select"
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          <option selected disabled>
            Velg prosjekttype
          </option>
          {types?.map((type, typeKey) => {
            return (
              <option key={typeKey} value={type.name}>
                {type.name}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
}

const Historikk = ({ type }) => {
  const [data, setData] = useState([]);
  const [openVersion, setOpenVersions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/history.php?historikk=" + type,
          { credentials: "include" }
        );
        const data = await res.json();
        setData(data);
      } catch (err) {
        return;
      }
    };
    getData();
  }, []);

  const getTotal = (year, version, e) => {
    let total = 0;

    // Loop igjennom
    for (let i = 0; i < data.length; i++) {
      if (data[i].year === year) {
        // Year check
        const d = data[i];
        for (let j = 0; j < d?.data?.length; j++) {
          const dd = d.data[j];
          // Version check
          if (dd.version === version) {
            // Get economy
            for (let k = 0; k < dd?.subData?.length; k++) {
              const ddd = dd.subData[k];
              switch (e) {
                case 1:
                  if (ddd.e1) {
                    total += parseInt(ddd.e1);
                  }
                  break;
                case 2:
                  if (ddd.e2) {
                    total += parseInt(ddd.e2);
                  }
                  break;
                case 3:
                  if (ddd.e3) {
                    total += parseInt(ddd.e3);
                  }
                  break;
                case 4:
                  if (ddd.e4) {
                    total += parseInt(ddd.e4);
                  }
                  break;
                case 5:
                  if (ddd.e5) {
                    total += parseInt(ddd.e5);
                  }
                  break;
                case 6:
                  if (ddd.e6) {
                    total += parseInt(ddd.e6);
                  }
                  break;
                case 7:
                  if (ddd.e7) {
                    total += parseInt(ddd.e7);
                  }
                  break;
                case 8:
                  if (ddd.e8) {
                    total += parseInt(ddd.e8);
                  }
                  break;
                case 9:
                  if (ddd.e9) {
                    total += parseInt(ddd.e9);
                  }
                  break;
                case 10:
                  if (ddd.e10) {
                    total += parseInt(ddd.e10);
                  }
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
    }

    return convertNumber(total);
  };

  const getTotalProject = (year, version, e, pnr) => {
    let total = 0;

    // Loop igjennom
    for (let i = 0; i < data.length; i++) {
      if (data[i].year === year) {
        // Year check
        const d = data[i];
        for (let j = 0; j < d?.data?.length; j++) {
          const dd = d.data[j];
          // Version check
          if (parseInt(dd.version) === parseInt(version)) {
            // Get economy
            for (let k = 0; k < dd?.subData?.length; k++) {
              const ddd = dd.subData[k];
              if (ddd.pnr === pnr) {
                switch (e) {
                  case 1:
                    if (ddd.e1) {
                      total += parseInt(ddd.e1);
                    }
                    break;
                  case 2:
                    if (ddd.e2) {
                      total += parseInt(ddd.e2);
                    }
                    break;
                  case 3:
                    if (ddd.e3) {
                      total += parseInt(ddd.e3);
                    }
                    break;
                  case 4:
                    if (ddd.e4) {
                      total += parseInt(ddd.e4);
                    }
                    break;
                  case 5:
                    if (ddd.e5) {
                      total += parseInt(ddd.e5);
                    }
                    break;
                  case 6:
                    if (ddd.e6) {
                      total += parseInt(ddd.e6);
                    }
                    break;
                  case 7:
                    if (ddd.e7) {
                      total += parseInt(ddd.e7);
                    }
                    break;
                  case 8:
                    if (ddd.e8) {
                      total += parseInt(ddd.e8);
                    }
                    break;
                  case 9:
                    if (ddd.e9) {
                      total += parseInt(ddd.e9);
                    }
                    break;
                  case 10:
                    if (ddd.e10) {
                      total += parseInt(ddd.e10);
                    }
                    break;
                  default:
                    break;
                }
              }
            }
          }
        }
      }
    }

    return total;
  };

  return (
    <>
      <h6>Prosjekthistorikk for {type}</h6>
      <hr />
      {data?.map((d, k) => {
        return (
          <React.Fragment key={k}>
            <h6
              style={{
                padding: 5,
                backgroundColor: "#ededed",
                width: "100%",
              }}
            >
              <i className="bi bi-arrow-bar-right" /> Regnskapsdata for {d.year}
            </h6>
            <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
              <Table hover className="historyJSTable">
                <thead style={{ position: "sticky", top: -2, zIndex: 2 }}>
                  <tr>
                    <th style={{ width: "5%" }}>Dato</th>
                    <th style={{ width: "5%" }}>Beskrivelse</th>
                    {Array.from({ length: 10 }, (_, index) => {
                      return (
                        <th
                          key={index}
                          style={{ textAlign: "center", width: "8,5%" }}
                        >
                          {parseInt(d.year) + index}
                        </th>
                      );
                    })}
                    <th style={{ width: "2.5%" }}>Endring</th>
                    <th style={{ width: "2.5%" }}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {d?.data?.map((dd, kk) => {
                    return (
                      <React.Fragment key={kk}>
                        <tr
                          style={{ cursor: "pointer" }}
                          className={
                            openVersion.includes(d.year + "-" + dd.version)
                              ? "historyPinned"
                              : "notPinned"
                          }
                          onClick={() => {
                            const this_version = d.year + "-" + dd.version;
                            if (openVersion.includes(this_version)) {
                              setOpenVersions((prev) => {
                                return prev.filter(
                                  (item) => item !== this_version
                                );
                              });
                            } else {
                              setOpenVersions((prev) => {
                                //return [...prev, this_version];
                                return [this_version];
                              });
                            }
                          }}
                        >
                          <td>{convertDateFormat(dd.ended)}</td>
                          <td>{dd.comment}</td>
                          <td
                            style={{
                              textAlign: "center",
                              borderRight: "1px #000 solid",
                            }}
                          >
                            {convertNumber(dd.e1)}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              borderRight: "1px #000 solid",
                            }}
                          >
                            {convertNumber(dd.e2)}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              borderRight: "1px #000 solid",
                            }}
                          >
                            {convertNumber(dd.e3)}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              borderRight: "1px #000 solid",
                            }}
                          >
                            {convertNumber(dd.e4)}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              borderRight: "1px #000 solid",
                            }}
                          >
                            {convertNumber(dd.e5)}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              borderRight: "1px #000 solid",
                            }}
                          >
                            {convertNumber(dd.e6)}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              borderRight: "1px #000 solid",
                            }}
                          >
                            {convertNumber(dd.e7)}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              borderRight: "1px #000 solid",
                            }}
                          >
                            {convertNumber(dd.e8)}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              borderRight: "1px #000 solid",
                            }}
                          >
                            {convertNumber(dd.e9)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {convertNumber(dd.e10)}
                          </td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        {dd?.subData?.map((ddd, kkk) => {
                          return (
                            <React.Fragment key={kkk}>
                              <tr
                                className={
                                  openVersion.includes(
                                    d.year + "-" + dd.version
                                  )
                                    ? "history-sub"
                                    : "d-none"
                                }
                              >
                                <td style={{ paddingLeft: 25 }}>
                                  {convertDateFormat(ddd.updated)}
                                </td>
                                <td style={{ paddingLeft: 25 }}>
                                  <span className="text-truncate text-wrap">
                                    {ddd.pnr} - {ddd.pname}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px #000 solid",
                                  }}
                                  className="text-center"
                                >
                                  {convertNumber(ddd.e1)} <br />
                                  <span className="text-muted text-center  fs-6">
                                    {convertNumber(ddd.e1_old)}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px #000 solid",
                                  }}
                                  className="text-center"
                                >
                                  {convertNumber(ddd.e2)} <br />
                                  <span className="text-muted text-center">
                                    {convertNumber(ddd.e2_old)}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px #000 solid",
                                  }}
                                  className="text-center"
                                >
                                  {convertNumber(ddd.e3)} <br />
                                  <span className="text-muted text-center">
                                    {convertNumber(ddd.e3_old)}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px #000 solid",
                                  }}
                                  className="text-center"
                                >
                                  {convertNumber(ddd.e4)} <br />
                                  <span className="text-muted text-center">
                                    {convertNumber(ddd.e4_old)}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px #000 solid",
                                  }}
                                  className="text-center"
                                >
                                  {convertNumber(ddd.e5)} <br />
                                  <span className="text-muted text-center">
                                    {convertNumber(ddd.e5_old)}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px #000 solid",
                                  }}
                                  className="text-center"
                                >
                                  {convertNumber(ddd.e6)} <br />
                                  <span className="text-muted text-center">
                                    {convertNumber(ddd.e6_old)}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px #000 solid",
                                  }}
                                  className="text-center"
                                >
                                  {convertNumber(ddd.e7)} <br />
                                  <span className="text-muted text-center">
                                    {convertNumber(ddd.e7_old)}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px #000 solid",
                                  }}
                                  className="text-center"
                                >
                                  {convertNumber(ddd.e8)} <br />
                                  <span className="text-muted text-center">
                                    {convertNumber(ddd.e8_old)}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    borderRight: "1px #000 solid",
                                  }}
                                  className="text-center"
                                >
                                  {convertNumber(ddd.e9)} <br />
                                  <span className="text-muted text-center">
                                    {convertNumber(ddd.e9_old)}
                                  </span>
                                </td>
                                <td className="text-center">
                                  {convertNumber(ddd.e10)} <br />
                                  <span className="text-muted text-center">
                                    {convertNumber(ddd.e10_old)}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-muted text-center">
                                    {convertNumber(ddd.change_amount)}
                                  </span>
                                </td>
                                <td>
                                  {ddd.comment && (
                                    <ButtonToolbar>
                                      <OverlayTrigger
                                        placement="left"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            {ddd.comment}
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          className="bi bi-chat-left-dots"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </OverlayTrigger>
                                    </ButtonToolbar>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

const Temafordeling = ({ type }) => {
  const [data, setData] = useState([]);
  const [temaTypes, setTemaTypes] = useState([]);
  const [year, setYear] = useState(0);
  const [openVersion, setOpenVersions] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/history.php?tema=" +
            type +
            "&year=" +
            year,
          { credentials: "include" }
        );
        const data = await res.json();

        // Get types// Bruk et sett for å hente unike tematyper
        const uniqueTemaTypes = new Set();

        data.forEach((item) => {
          uniqueTemaTypes.add(item.tematype);
        });

        // Oppdater state med unike tematyper
        setTemaTypes([...uniqueTemaTypes]);

        setData(data);
      } catch (err) {
        return;
      }
    };
    getData();
  }, [year]);

  const totalTemaSum = (tematype, ec) => {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].tematype === tematype) {
        if (ec === 1) {
          total += parseInt(data[i].e1);
        }
        if (ec === 2) {
          total += parseInt(data[i].e2);
        }
        if (ec === 3) {
          total += parseInt(data[i].e3);
        }
        if (ec === 4) {
          total += parseInt(data[i].e4);
        }
      }
    }
    return total;
  };

  const totalSum = (ec) => {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      if (ec === 1) {
        total += parseInt(data[i].e1);
      }
      if (ec === 2) {
        total += parseInt(data[i].e2);
      }
      if (ec === 3) {
        total += parseInt(data[i].e3);
      }
      if (ec === 4) {
        total += parseInt(data[i].e4);
      }
    }
    return total;
  };

  return (
    <>
      <h6>Temafordeling for {type}</h6>
      <hr />
      <div className="d-flex mb-2">
        <div>
          {year > 0 && (
            <Button
              size="sm"
              className="me-2"
              onClick={() => setYear((p) => p - 1)}
            >
              Forrige år
            </Button>
          )}
          <Button size="sm" onClick={() => setYear((p) => p + 1)}>
            Neste år
          </Button>
        </div>
      </div>
      <div>
        <Table className="historyTema">
          <thead className="d-none">
            <tr>
              <th>Tema</th>
              <th>{data?.[0]?.year + year}</th>
              <th>{parseInt(data?.[0]?.year) + 1 + year}</th>
              <th>{parseInt(data?.[0]?.year) + 2 + year}</th>
              <th>{parseInt(data?.[0]?.year) + 3 + year}</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {temaTypes?.map((tematype, temakey) => {
              return (
                <>
                  <tr className="temaType" key={temakey}>
                    <th>{tematype ? tematype : "Ingen tema"}</th>
                    <th>{parseInt(data?.[0]?.year) + year}</th>
                    <th>{parseInt(data?.[0]?.year) + 1 + year}</th>
                    <th>{parseInt(data?.[0]?.year) + 2 + year}</th>
                    <th>{parseInt(data?.[0]?.year) + 3 + year}</th>
                    <th>Total</th>
                  </tr>
                  {data?.map((d, k) => {
                    if (d.tematype === tematype) {
                      return (
                        <tr key={k}>
                          <td>
                            {d.tema} - {d.temanavn}
                          </td>
                          <td>{convertNumber(d.e1)}</td>
                          <td>{convertNumber(d.e2)}</td>
                          <td>{convertNumber(d.e3)}</td>
                          <td>{convertNumber(d.e4)}</td>
                          <td>{convertNumber(d.total)}</td>
                        </tr>
                      );
                    }
                  })}
                  <tr>
                    <th>Sum for {tematype}</th>
                    <th>{convertNumber(totalTemaSum(tematype, 1))}</th>
                    <th>{convertNumber(totalTemaSum(tematype, 2))}</th>
                    <th>{convertNumber(totalTemaSum(tematype, 3))}</th>
                    <th>{convertNumber(totalTemaSum(tematype, 4))}</th>
                    <th>
                      {convertNumber(
                        totalTemaSum(tematype, 1) +
                          totalTemaSum(tematype, 2) +
                          totalTemaSum(tematype, 3) +
                          totalTemaSum(tematype, 4)
                      )}
                    </th>
                  </tr>
                </>
              );
            })}
            <tr className="temaTotal">
              <th>Totalt</th>
              <th>{convertNumber(totalSum(1))}</th>
              <th>{convertNumber(totalSum(2))}</th>
              <th>{convertNumber(totalSum(3))}</th>
              <th>{convertNumber(totalSum(4))}</th>
              <th>
                {convertNumber(
                  totalSum(1) + totalSum(2) + totalSum(3) + totalSum(4)
                )}
              </th>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

const Avviksanalyse = ({ type }) => {
  const [typeData, setTypeData] = useState([]);
  const [data, setData] = useState([]);

  // Options
  const [fromYear, setFromYear] = useState(null);
  const [fromVersion, setFromVersion] = useState(null);
  const [fromName, setFromName] = useState(null);
  const [toYear, setToYear] = useState(null);
  const [toVersion, setToVersion] = useState(null);
  const [toName, setToName] = useState(null);

  // Expanded Proj
  const [expandedProj, setExpandedProj] = useState(null);

  // Hoved eller underprosjetker
  const [onlyMainProjects, setOnlyMainProjects] = useState(false);

  useEffect(() => {
    const getTypeData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/history.php?avviksanalyse&getType=" +
            type,
          { credentials: "include" }
        );
        const data = await res.json();
        setTypeData(data);
      } catch (err) {
        return;
      }
    };
    getTypeData();
  }, []);

  const runReport = async (mainOnly = false) => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/history.php?avviksanalyse&runType=" +
          type +
          "&fromYear=" +
          fromYear +
          "&fromVersion=" +
          fromVersion +
          "&toYear=" +
          toYear +
          "&toVersion=" +
          toVersion +
          "&mainOnly=" +
          mainOnly,
        { credentials: "include" }
      );
      const data = await res.json();
      setData(data);
    } catch (err) {
      alert("Noe gikk galt...");
    }
  };

  const getTotal = (n) => {
    let total = 0;
    data.forEach((d) => {
      if (n === 1) {
        total += parseInt(d.e1);
      }
      if (n === 2) {
        total += parseInt(d.e2);
      }
    });

    return total;
  };

  return (
    <>
      <h6>Avviksanalyse for {type}</h6>
      {typeData.length <= 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <Spinner />
          </div>
        </div>
      )}

      {typeData.length > 0 && data.length <= 0 && (
        <>
          <div style={{ maxWidth: "1200px" }}>
            <div className="d-flex justify-content-center">
              <div className="p-2" style={{ minWidth: "600px" }}>
                <h6>1. Velg kolonne</h6>
                <div className="content">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      const selectedOption =
                        e.target.options[e.target.selectedIndex];
                      const year = selectedOption.getAttribute("data-year");
                      const version =
                        selectedOption.getAttribute("data-version");
                      const name = selectedOption.getAttribute("data-name");
                      setFromYear(year);
                      setFromVersion(version);
                      setFromName(name);
                    }}
                  >
                    <option selected disabled>
                      Velg kolonne
                    </option>
                    {typeData?.map((type, typeKey) => {
                      return (
                        <option
                          data-year={type.year}
                          data-version={type.version}
                          data-name={type.comment}
                          key={typeKey}
                        >
                          {type.year} - {type.comment}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="p-2" style={{ minWidth: "600px" }}>
                <h6>2. Velg kolonne</h6>
                <div className="content">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      const selectedOption =
                        e.target.options[e.target.selectedIndex];
                      const year = selectedOption.getAttribute("data-year");
                      const version =
                        selectedOption.getAttribute("data-version");
                      const name = selectedOption.getAttribute("data-name");
                      setToYear(year);
                      setToVersion(version);
                      setToName(name);
                    }}
                  >
                    <option selected disabled>
                      Velg kolonne
                    </option>
                    {typeData?.map((type, typeKey) => {
                      return (
                        <option
                          data-year={type.year}
                          data-version={type.version}
                          data-name={type.comment}
                          key={typeKey}
                        >
                          {type.year} - {type.comment}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end h-100">
              <div className="d-flex align-items-end">
                <Button onClick={() => runReport()}>Kjør rapport</Button>
              </div>
            </div>
          </div>
        </>
      )}

      {data?.length > 0 && (
        <>
          <div
            className="d-flex justify-content-end"
            style={{ maxWidth: "1200px" }}
          >
            <Button
              size="sm"
              onClick={() => {
                setOnlyMainProjects((prev) => {
                  runReport(!prev);
                  return !prev;
                });
              }}
              variant="primary"
              style={{ marginBottom: 5, marginRight: 5 }}
            >
              {onlyMainProjects
                ? "Vis alle prosjekter"
                : "Vis kun hovedprosjekter"}
            </Button>
            <Button
              size="sm"
              onClick={() => setData([])}
              variant="warning"
              style={{ marginBottom: 5 }}
            >
              Nullstill
            </Button>
          </div>
          <div
            className="table-responsive"
            style={{ maxWidth: "1200px", maxHeight: "80vh" }}
          >
            <Table>
              <thead>
                <tr style={{ position: "sticky", top: 0, zIndex: 2 }}>
                  <th>Prosjekt</th>
                  <th>{fromName}</th>
                  <th>{toName}</th>
                  <th>Avvik</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((d, k) => {
                  return (
                    <tr
                      key={k}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setExpandedProj((prev) => {
                          if (prev === d.pnr) {
                            return null;
                          }
                          return d.pnr;
                        })
                      }
                    >
                      <td
                        style={
                          expandedProj === d.pnr ? { fontWeight: "bold" } : {}
                        }
                      >
                        {d.pnr} - {d.pname}
                        {expandedProj === d.pnr && (
                          <>
                            <hr />
                            <div className="d-flex flex-column">
                              {d?.subData?.map((subD, subK) => {
                                return (
                                  <span key={subK} className="text-muted">
                                    {subD.tjeneste}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </td>
                      <td
                        style={
                          expandedProj === d.pnr ? { fontWeight: "bold" } : {}
                        }
                      >
                        {convertNumber(d.e1)}
                        {expandedProj === d.pnr && (
                          <>
                            <hr />
                            <div className="d-flex flex-column">
                              {d?.subData?.map((subD, subK) => {
                                return (
                                  <span key={subK} className="text-muted">
                                    {convertNumber(subD.e1)}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </td>
                      <td
                        style={
                          expandedProj === d.pnr ? { fontWeight: "bold" } : {}
                        }
                      >
                        {convertNumber(d.e2)}
                        {expandedProj === d.pnr && (
                          <>
                            <hr />
                            <div className="d-flex flex-column">
                              {d?.subData?.map((subD, subK) => {
                                return (
                                  <span key={subK} className="text-muted">
                                    {convertNumber(subD.e2)}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </td>
                      <td
                        style={
                          expandedProj === d.pnr ? { fontWeight: "bold" } : {}
                        }
                      >
                        {convertNumber(d.avvik)}
                        {expandedProj === d.pnr && (
                          <>
                            <hr />
                            <div className="d-flex flex-column">
                              {d?.subData?.map((subD, subK) => {
                                return (
                                  <span key={subK} className="text-muted">
                                    {convertNumber(subD.avvik)}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </td>

                      <td>
                        <ProjectHistory project={d.pnr}>
                          <i className="bi bi-chat" />
                        </ProjectHistory>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th>&nbsp;</th>
                  <th>{convertNumber(getTotal(1))}</th>
                  <th>{convertNumber(getTotal(2))}</th>
                  <th>{convertNumber(getTotal(2) - getTotal(1))}</th>
                  <th>&nbsp;</th>
                </tr>
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

const Ressursstyring = ({ type }) => {
  const [data, setData] = useState([]);
  const [activePL, setActivePL] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const sortActivePL = () => {
    setData((prev) => {
      // Sjekk om prev.plData eksisterer og er en array, ellers bruk en tom array
      const plData = prev?.plData ?? [];

      // Sjekk om activePL er en array og har verdier
      if (Array.isArray(activePL) && activePL.length > 0) {
        const sortedData = [...plData].sort((a, b) => {
          // Sjekker om prosjektlederen finnes i activePL
          const isAActive = activePL.includes(a.prosjektleder);
          const isBActive = activePL.includes(b.prosjektleder);

          // Sorteringslogikk:
          if (isAActive && !isBActive) return -1; // a kommer før b
          if (!isAActive && isBActive) return 1; // b kommer før a
          return 0; // ingen endring i rekkefølge
        });

        // Returner en ny kopi av prev med sortert plData
        return { ...prev, plData: sortedData };
      }

      // Hvis activePL er tom eller ikke en array, returner prev state uendret
      return prev;
    });
  };

  const getData = async () => {
    let isMounted = true;
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/history.php?ressurs&getType=" + type,
        { credentials: "include" }
      );
      const data = await res.json();
      setData(data);
    } catch (err) {
      return;
    }

    return () => {
      isMounted = false;
    };
  };

  const getFase = (prosjekt, year) => {
    const faser = [];

    data?.pFaser?.forEach((item) => {
      if (item.prosjektnr === prosjekt) {
        const start = new Date(item.start);
        const end = new Date(item.slutt);
        const yearStart = new Date(`${year}-01-01`);
        const yearEnd = new Date(`${year}-12-31`);

        // Check if the phase overlaps with the year
        if (start <= yearEnd && end >= yearStart) {
          const phaseStart = start < yearStart ? yearStart : start;
          const phaseEnd = end > yearEnd ? yearEnd : end;

          // Calculate the gap from the start of the year or after the last phase
          const previousEnd =
            faser.length > 0 ? faser[faser.length - 1].end : yearStart;
          const gapDuration =
            (phaseStart - previousEnd) / (1000 * 60 * 60 * 24); // Dager i gapet

          if (gapDuration > 0) {
            const gapWidth = (gapDuration / 365) * 100; // Prosentandel av året
            faser.push({
              fase: "dummy",
              width: gapWidth,
              start: previousEnd,
              end: phaseStart,
            });
          }

          const totalDuration = (end - start) / (1000 * 60 * 60 * 24); // Total dager
          const duration = (phaseEnd - phaseStart) / (1000 * 60 * 60 * 24); // Dager i dette året

          if (duration > 0) {
            const width = (duration / 365) * 100; // Prosentandel av året
            faser.push({
              fase: item.fase,
              width,
              start: phaseStart,
              end: phaseEnd,
            });
          }
        }
      }
    });

    return faser;
  };

  const TimelineForYear = ({ prosjekt, year }) => {
    const faser = getFase(prosjekt, year);

    const getColor = (fase) => {
      switch (fase.toLowerCase()) {
        case "skisse/forprosjekt":
          return "red";
        case "detaljprosjektering":
          return "yellow";
        case "utførelse":
          return "blue";
        case "dummy":
          return "transparent";
        default:
          return "grey";
      }
    };

    return (
      <div style={{ display: "flex", width: "100%" }}>
        {faser?.map((item, index) => (
          <div
            key={index}
            style={{
              width: `${item.width}%`,
              height: 5,
              backgroundColor: getColor(item.fase),
            }}
          />
        ))}
      </div>
    );
  };

  const getFaseOLD = (prosjekt, year) => {
    const faser = [];

    data?.pFaser?.forEach((item) => {
      if (item.prosjektnr === prosjekt) {
        const start = new Date(item.start);
        const end = new Date(item.slutt);
        const yearStart = new Date(`${year}-01-01`);
        const yearEnd = new Date(`${year}-12-31`);

        // Check if the phase overlaps with the year
        if (start <= yearEnd && end >= yearStart) {
          const phaseStart = start < yearStart ? yearStart : start;
          const phaseEnd = end > yearEnd ? yearEnd : end;
          const totalDuration = (end - start) / (1000 * 60 * 60 * 24); // Total dager
          const duration = (phaseEnd - phaseStart) / (1000 * 60 * 60 * 24); // Dager i dette året

          if (duration > 0) {
            const width = (duration / 365) * 100; // Prosentandel av året
            faser.push({
              fase: item.fase,
              width,
            });
          }
        }
      }
    });

    return faser;
  };

  const TimelineForYearOLD = ({ prosjekt, year }) => {
    const faser = getFase(prosjekt, year);

    const getColor = (fase) => {
      switch (fase.toLowerCase()) {
        case "skisse/forprosjekt":
          return "red";
        case "detaljprosjektering":
          return "yellow";
        case "utførelse":
          return "blue";
        case "dummy":
          return "white";
        default:
          return "grey";
      }
    };

    return (
      <div style={{ display: "flex", width: "100%" }}>
        {faser?.map((item, index) => (
          <div
            key={index}
            style={{
              width: `${item.width}%`,
              height: 5,
              backgroundColor: getColor(item.fase),
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="table-responsive">
      <div>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <div
              style={{
                flex: 1,
                height: 5,
                borderTop: "5px solid red",
              }}
            />
            <div
              style={{
                flex: 1,
                height: 5,
                borderTop: "5px solid yellow",
              }}
            />
            <div
              style={{
                flex: 1,
                height: 5,
                borderTop: "5px solid blue",
              }}
            />
          </div>
          <div className="d-flex justify-content-around">
            <span className="p-2">Skisse/forprosjekt</span>
            <span className="p-2">Detaljprosjektering</span>
            <span className="p-2">Utførelse </span>
          </div>
        </div>
      </div>

      <div className="overflow-auto" style={{ maxHeight: "75vh" }}>
        <table className="table table-striped table-bordered table-hover">
          <thead
            className="table-primary table-striped"
            style={{
              position: "sticky",
              top: -2,
              zIndex: 2,
            }}
          >
            <tr>
              <th scope="col" style={{ width: "25%" }}>
                Prosjektleder
              </th>
              <th className="text-center" scope="col" style={{ width: "7.5%" }}>
                {data?.year}
              </th>
              <th className="text-center" scope="col" style={{ width: "7.5%" }}>
                {data?.year + 1}
              </th>
              <th className="text-center" scope="col" style={{ width: "7.5%" }}>
                {data?.year + 2}
              </th>
              <th className="text-center" scope="col" style={{ width: "7.5%" }}>
                {data?.year + 3}
              </th>
              <th className="text-center" scope="col" style={{ width: "7.5%" }}>
                {data?.year + 4}
              </th>
              <th className="text-center" scope="col" style={{ width: "7.5%" }}>
                {data?.year + 5}
              </th>
              <th className="text-center" scope="col" style={{ width: "7.5%" }}>
                {data?.year + 6}
              </th>
              <th className="text-center" scope="col" style={{ width: "7.5%" }}>
                {data?.year + 7}
              </th>
              <th className="text-center" scope="col" style={{ width: "7.5%" }}>
                {data?.year + 8}
              </th>
              <th className="text-center" scope="col" style={{ width: "7.5%" }}>
                {data?.year + 9}
              </th>
            </tr>
          </thead>
          <tbody className="table-light">
            {Object.keys(data).length > 0 && (
              <>
                {data?.plData?.map((pl, plKey) => (
                  <React.Fragment key={plKey}>
                    <tr
                      key={plKey}
                      onClick={() => {
                        setActivePL((prev) => {
                          if (prev.includes(pl.prosjektleder)) {
                            return prev.filter(
                              (leader) => leader !== pl.prosjektleder
                            );
                          } else {
                            return [...prev, pl.prosjektleder];
                          }
                        });
                      }}
                    >
                      <th scope="row">{pl.prosjektleder}</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    {activePL.includes(pl.prosjektleder) && (
                      <>
                        {pl.data.map((prosj, index) => (
                          <tr key={index + "prosj"}>
                            <td>
                              {prosj.prosjektnr} - {prosj.prosjektnavn}
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "5px 0px 0px 0px" }}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Tooltip on top"
                            >
                              <div className="d-flex flex-column">
                                <span>{convertNumber(pl.data[index].ø1)}</span>
                                {pl.data[index].ø1 && (
                                  <TimelineForYear
                                    prosjekt={prosj.prosjektnr}
                                    year={data.year}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "5px 0px 0px 0px" }}
                            >
                              <div className="d-flex flex-column">
                                <span>{convertNumber(pl.data[index].ø2)}</span>
                                {pl.data[index].ø2 && (
                                  <TimelineForYear
                                    prosjekt={prosj.prosjektnr}
                                    year={data.year + 1}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "5px 0px 0px 0px" }}
                            >
                              <div className="d-flex flex-column">
                                <span>{convertNumber(pl.data[index].ø3)}</span>
                                {pl.data[index].ø3 && (
                                  <TimelineForYear
                                    prosjekt={prosj.prosjektnr}
                                    year={data.year + 2}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "5px 0px 0px 0px" }}
                            >
                              <div className="d-flex flex-column">
                                <span>{convertNumber(pl.data[index].ø4)}</span>
                                {pl.data[index].ø4 && (
                                  <TimelineForYear
                                    prosjekt={prosj.prosjektnr}
                                    year={data.year + 3}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "5px 0px 0px 0px" }}
                            >
                              <div className="d-flex flex-column">
                                <span>{convertNumber(pl.data[index].ø5)}</span>
                                {pl.data[index].ø5 && (
                                  <TimelineForYear
                                    prosjekt={prosj.prosjektnr}
                                    year={data.year + 4}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "5px 0px 0px 0px" }}
                            >
                              <div className="d-flex flex-column">
                                <span>{convertNumber(pl.data[index].ø6)}</span>
                                {pl.data[index].ø6 && (
                                  <TimelineForYear
                                    prosjekt={prosj.prosjektnr}
                                    year={data.year + 5}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "5px 0px 0px 0px" }}
                            >
                              <div className="d-flex flex-column">
                                <span>{convertNumber(pl.data[index].ø7)}</span>
                                {pl.data[index].ø7 && (
                                  <TimelineForYear
                                    prosjekt={prosj.prosjektnr}
                                    year={data.year + 6}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "5px 0px 0px 0px" }}
                            >
                              <div className="d-flex flex-column">
                                <span>{convertNumber(pl.data[index].ø8)}</span>
                                {pl.data[index].ø8 && (
                                  <TimelineForYear
                                    prosjekt={prosj.prosjektnr}
                                    year={data.year + 7}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "5px 0px 0px 0px" }}
                            >
                              <div className="d-flex flex-column">
                                <span>{convertNumber(pl.data[index].ø9)}</span>
                                {pl.data[index].ø9 && (
                                  <TimelineForYear
                                    prosjekt={prosj.prosjektnr}
                                    year={data.year + 8}
                                  />
                                )}
                              </div>
                            </td>
                            <td
                              className="text-center"
                              style={{ padding: "5px 0px 0px 0px" }}
                            >
                              <div className="d-flex flex-column">
                                <span>{convertNumber(pl.data[index].ø10)}</span>
                                {pl.data[index].ø10 && (
                                  <TimelineForYear
                                    prosjekt={prosj.prosjektnr}
                                    year={data.year + 9}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>

      <Button size="sm" onClick={() => sortActivePL()}>
        Sorter prosjektledere
      </Button>
    </div>
  );
};
