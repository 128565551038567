import React, { useEffect, useState, useMemo } from "react";
import { Alert, Button, Dropdown, Spinner, Table } from "react-bootstrap";
import ProjectHistory from "./history";

import { getCookie, removeCookie, setCookie } from "../../utils/cookie";

const convertNumber = (number) => {
  let num = parseInt(number);
  if (isNaN(num)) {
    return number;
  }
  return num.toLocaleString();
};
const formatName = (fullName) => {
  try {
    const nameParts = fullName.split(" "); // Split navnet ved mellomrom
    if (nameParts.length <= 1) return fullName; // Hvis det bare er ett navn, returner det som det er

    const firstName = nameParts[0]; // Første del av navnet
    const initials = nameParts
      .slice(1)
      .map((name) => `${name.charAt(0)}.`)
      .join(" "); // Resten av delene som initialer

    return `${firstName} ${initials}`; // Kombiner fornavn og initialer
  } catch (err) {
    return fullName;
  }
};

export default function Projects() {
  const [loaded, setLoaded] = useState(false); // false
  const [error, setError] = useState(false);

  const [data, setData] = useState([]); /// Main Data
  const [activeSub, setActiveSub] = useState([]); // Underprosjekt av enhet/type
  const [activeSubSub, setActiveSubSub] = useState([]); // Underprosjekt av prosjekt

  const [updated, setUpdated] = useState("");

  // Pinned
  const [pinList, setPinList] = useState([]);
  const pinHeight = 40;

  // FIlter
  const [filteredTypes, setFilteredTypes] = useState([]);
  const [bigTable, setBigTable] = useState(false);

  // Filter PL
  const [PLs, setPLs] = useState([]);
  const [filteredPL, setFilteredPL] = useState([]);

  useEffect(() => {
    getData();

    return () => {
      setData([]);
      setLoaded(false);
      setError(false);
    };
  }, []);

  const getData = async () => {
    try {
      const res = await fetch("https://prosjekt.tkva.no/api/projects.php", {
        credentials: "include",
      });
      const data = await res.json();

      // Hente ut alle PL
      let PLs = new Set();

      // Iterere gjennom hovedprosjektene
      data.forEach((type) => {
        // PL på hovedprosjekter
        type.subData?.forEach((subData) => {
          if (subData.pl) {
            PLs.add(subData.pl);
          }
          // PL på underprosjekter
          subData.subData?.forEach((subsubData) => {
            if (subsubData.pl) {
              PLs.add(subsubData.pl);
            }
          });
        });
      });

      // Konvertere Set til en array hvis nødvendig
      let uniquePLs = Array.from(PLs);

      setPLs(uniquePLs);

      setData(data);
      setLoaded(true);
      setUpdated(new Date().toLocaleTimeString());

      // Check cookie for filter types
      if (getCookie("filteredTypes")) {
        setFilteredTypes(getCookie("filteredTypes"));
      }
    } catch (err) {
      setError(err.message);
      setLoaded(true);
    }
  };

  const sortedPinList = useMemo(() => {
    const comparePinItems = (a, b) => {
      // Sammenlign parentIds først
      if (a.parentId !== b.parentId) {
        return a.parentId - b.parentId;
      }

      // Hvis parentIds er like, sammenlign subIds
      if (a.subId !== b.subId) {
        return a.subId - b.subId;
      }

      // Hvis subIds også er like, sammenlign subsubIds
      if (a.subsubId !== b.subsubId) {
        return a.subsubId - b.subsubId;
      }

      return 0;
    };

    return [...pinList].sort(comparePinItems);
  }, [pinList]);

  // PL filter in table
  const PLinType = (type) => {
    // SJekke om PL finnes i denne prosjekttypen
    if (filteredPL.length <= 0) {
      return true;
    } else {
      // Sjekk om PL finnes i hovedprosjekt eller underprosjekt
      return data.some((_type) => {
        if (_type.name === type) {
          return _type.subData?.some((subData) => {
            if (filteredPL.includes(subData.pl)) {
              return true;
            }
            // PL på underprosjekter
            return subData.subData?.some((subsubData) => {
              return filteredPL.includes(subsubData.pl);
            });
          });
        }
        return false;
      });
    }
  };
  const PLinMain = (type, main) => {
    // Sjekke om PL finnes i main eller sub
    if (filteredPL.length <= 0) {
      return true;
    } else {
      return data.some((t) => {
        if (t.name === type) {
          return t.subData?.some((subData) => {
            if (subData.pnr === main && filteredPL.includes(subData.pl)) {
              return true;
            }
            // Sjekke om PL finnes i subData til subData
            return subData.subData?.some((subsubData) => {
              return filteredPL.includes(subsubData.pl);
            });
          });
        }
        return false;
      });
    }
  };

  return (
    <>
      {loaded ? (
        <>
          {error ? (
            <Alert variant="danger">
              <b>Noe gikk galt.</b> Prøv igjen senere <br />{" "}
              <span className="err-msg text-muted">Error: {error}</span>
            </Alert>
          ) : (
            <>
              {/*
               ** Hvis vi skal tillatte ulike årstall må man se på en loop mellom {data[0].year} og {proj.year}
               */}

              <div className="row reverse">
                <div className="d-flex justify-content-between">
                  <span>Oppdatert {updated}</span>
                  <div className="d-flex flex-row-reverse mb-2">
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        variant={
                          filteredTypes.length > 0 ? "info" : "secondary"
                        }
                        size="sm"
                        id="dropdown-basic"
                      >
                        Filter (type)
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {data.map((d, k) => {
                          return (
                            <Dropdown.Item
                              key={k}
                              onClick={() => {
                                setFilteredTypes((prev) => {
                                  const curCookie = getCookie("filteredTypes");
                                  let newCookies = [];

                                  if (curCookie) {
                                    for (let i = 0; i < curCookie.length; i++) {
                                      if (curCookie[i] !== d.name) {
                                        newCookies.push(curCookie[i]);
                                      }
                                    }
                                  }

                                  if (prev.includes(d.name)) {
                                    setCookie("filteredTypes", newCookies);
                                    return prev.filter(
                                      (item) => item !== d.name
                                    );
                                  } else {
                                    newCookies.push(d.name);
                                    setCookie("filteredTypes", newCookies);
                                    return [...prev, d.name];
                                  }
                                });
                              }}
                            >
                              {filteredTypes.includes(d.name) ? (
                                <i
                                  className="bi bi-bookmark-x"
                                  style={{ color: "grey" }}
                                />
                              ) : (
                                <i
                                  className="bi bi-bookmark-check"
                                  style={{ color: "blue" }}
                                />
                              )}
                              {d.name}{" "}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown autoClose="outside" style={{ marginRight: 5 }}>
                      <Dropdown.Toggle
                        variant={filteredPL.length > 0 ? "info" : "secondary"}
                        size="sm"
                        id="dropdown-basic"
                      >
                        Filter (PL)
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {PLs.map((pl, plKey) => {
                          return (
                            <Dropdown.Item
                              key={plKey}
                              onClick={() => {
                                setFilteredPL((prev) => {
                                  if (prev.includes(pl)) {
                                    return prev.filter((item) => item !== pl);
                                  } else {
                                    return [...prev, pl];
                                  }
                                });
                              }}
                            >
                              {filteredPL.includes(pl) ? (
                                <i
                                  className="bi bi-bookmark-check"
                                  style={{ color: "blue" }}
                                />
                              ) : (
                                <i
                                  className="bi bi-bookmark-x"
                                  style={{ color: "grey" }}
                                />
                              )}
                              {pl}{" "}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button
                      size="sm"
                      variant="light"
                      style={{ marginRight: 5 }}
                      onClick={() => setBigTable((prev) => !prev)}
                    >
                      {bigTable ? "Liten tabell" : "Stor tabell"}
                    </Button>
                    <Button
                      onClick={() => getData()}
                      size="sm"
                      variant="light"
                      style={{ marginRight: 5 }}
                    >
                      <i className="bi bi-arrow-clockwise" />
                    </Button>
                  </div>
                </div>
              </div>

              <div
                id="prosjectTable"
                className="table-responsive"
                style={{
                  overflowY: "auto",
                  height: "95%",
                  cursor: "default",
                }}
              >
                <Table hover className="table" id="theProsjectTable">
                  <thead
                    style={{
                      position: "sticky",
                      top: -2,
                      zIndex: 1,
                    }}
                  >
                    <tr>
                      <th
                        colSpan={2}
                        style={{ textAlign: "left", width: "25%" }}
                      >
                        Prosjekt
                      </th>
                      <th>PL</th>
                      {bigTable
                        ? Array.from({ length: 10 }, (_, index) => (
                            <th key={index}>
                              {parseInt(data[0].year) + index}
                            </th>
                          ))
                        : Array.from({ length: 4 }, (_, index) => (
                            <th key={index}>
                              {parseInt(data[0].year) + index}
                            </th>
                          ))}
                      <th>#</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((proj, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr
                            className={
                              pinList.find((item) => item.pnr === proj.name)
                                ? `mainProject pinned ${
                                    filteredTypes.includes(proj.name) &&
                                    "d-none"
                                  }  ${!PLinType(proj.name) && "d-none"}`
                                : `mainProject ${
                                    filteredTypes.includes(proj.name) &&
                                    "d-none"
                                  }  ${!PLinType(proj.name) && "d-none"}`
                            }
                            style={
                              pinList.find((item) => item.pnr === proj.name)
                                ? {
                                    cursor: "pointer",
                                    height: "110px",
                                    top:
                                      pinHeight +
                                      parseInt(
                                        parseInt(
                                          sortedPinList.findIndex(
                                            (item) => item.pnr === proj.name
                                          )
                                        ) * 105
                                      ) +
                                      "px",
                                  }
                                : { cursor: "pointer", height: "110px" }
                            }
                            onClick={() => {
                              setActiveSub((prev) => {
                                if (prev.includes(proj.name)) {
                                  // Fjern prosjektet hvis det allerede er i listen
                                  return prev.filter(
                                    (name) => name !== proj.name
                                  );
                                } else {
                                  // Legg til prosjektet hvis det ikke er i listen
                                  return [...prev, proj.name];
                                }
                              });
                            }}
                          >
                            <td colSpan={2}>
                              <img
                                alt=""
                                src={proj.img}
                                height={25}
                                width={25}
                              />{" "}
                              {proj.name}
                              <br />
                              <small className="text-muted">
                                {proj.version}. Versjon: {proj.versionDesc}
                              </small>{" "}
                              <br />
                              <small className="text-muted">
                                Årstall: {proj.year}
                              </small>{" "}
                            </td>

                            <td>{formatName(proj.pl)}</td>

                            <td>{convertNumber(proj.e1)}</td>

                            <td>{convertNumber(proj.e2)}</td>

                            <td>{convertNumber(proj.e3)}</td>

                            <td>{convertNumber(proj.e4)}</td>
                            {bigTable && (
                              <>
                                <td>{convertNumber(proj.e5)}</td>

                                <td>{convertNumber(proj.e6)}</td>

                                <td>{convertNumber(proj.e7)}</td>

                                <td>{convertNumber(proj.e8)}</td>

                                <td>{convertNumber(proj.e9)}</td>

                                <td>{convertNumber(proj.e10)}</td>
                              </>
                            )}
                            <td
                              onClick={() =>
                                setActiveSub((prev) => {
                                  if (prev.includes(proj.name)) {
                                    // Fjern prosjektet hvis det allerede er i listen
                                    return prev.filter(
                                      (name) => name !== proj.name
                                    );
                                  } else {
                                    // Legg til prosjektet hvis det ikke er i listen
                                    return [...prev, proj.name];
                                  }
                                })
                              }
                            >
                              <>
                                {pinList.find(
                                  (item) => item.pnr === proj.name
                                ) ? (
                                  <i
                                    className="bi bi-pin-angle-fill"
                                    onClick={() =>
                                      setPinList((prev) =>
                                        prev.filter(
                                          (item) => item.pnr !== proj.name
                                        )
                                      )
                                    }
                                  />
                                ) : (
                                  <i
                                    className="bi bi-pin-angle"
                                    onClick={() =>
                                      setPinList((prev) => [
                                        ...prev,
                                        {
                                          pnr: proj.name,
                                          parentId: index,
                                          subId: null,
                                          subsubId: null,
                                        },
                                      ])
                                    }
                                  />
                                )}
                              </>
                            </td>
                          </tr>
                          {proj.subData.map((subProj, subIndex) => {
                            return (
                              <>
                                <tr
                                  key={subIndex}
                                  className={`
                                    ${
                                      pinList.find(
                                        (item) => item.pnr === subProj.pnr
                                      )
                                        ? `subProject
                                        ${
                                          filteredTypes.includes(proj.name) &&
                                          "d-none"
                                        }
                                        pinned pinCount${
                                          pinList.find(
                                            (item) => item.pnr === subProj.pnr
                                          )?.count
                                        } `
                                        : `subProject ${
                                            filteredTypes.includes(proj.name) &&
                                            "d-none"
                                          }`
                                    } ${
                                    !PLinMain(proj.name, subProj.pnr) &&
                                    "d-none"
                                  }
                                    `}
                                  style={
                                    activeSub.includes(proj.name)
                                      ? {
                                          display: "table-row",
                                          cursor: "pointer",
                                          height: "110px",
                                          top: pinList.find(
                                            (prev) => prev.pnr === subProj.pnr
                                          )
                                            ? pinHeight +
                                              parseInt(
                                                parseInt(
                                                  sortedPinList.findIndex(
                                                    (item) =>
                                                      item.pnr === subProj.pnr
                                                  )
                                                ) * 105
                                              ) +
                                              "px"
                                            : 0, // Sett toppen til 0 hvis elementet ikke er festet
                                        }
                                      : { display: "none", cursor: "pointer" }
                                  }
                                  onClick={() => {
                                    setActiveSubSub((prev) => {
                                      if (prev.includes(subProj.pnr)) {
                                        // Fjern prosjektet hvis det allerede er i listen
                                        return prev.filter(
                                          (name) => name !== subProj.pnr
                                        );
                                      } else {
                                        // Legg til prosjektet hvis det ikke er i listen
                                        return [...prev, subProj.pnr];
                                      }
                                    });
                                  }}
                                >
                                  <td colSpan={2}>
                                    <div
                                      className="d-flex flex-column"
                                      style={{ marginLeft: 25 }}
                                    >
                                      {subProj.pnr} - {subProj.pname}
                                      <span className="projType">
                                        <img
                                          alt=""
                                          src={proj.img}
                                          height={15}
                                          width={15}
                                        />{" "}
                                        {proj.name}
                                      </span>
                                    </div>
                                  </td>
                                  <td>{formatName(subProj.pl)}</td>
                                  <td>
                                    {convertNumber(subProj.e1)} <br />
                                    {subProj.mainE1 != 0 && (
                                      <span className="subAmount">
                                        ({convertNumber(subProj.mainE1)})
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {convertNumber(subProj.e2)} <br />
                                    {subProj.mainE2 != 0 && (
                                      <span className="subAmount">
                                        ({convertNumber(subProj.mainE2)})
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {convertNumber(subProj.e3)} <br />
                                    {subProj.mainE3 != 0 && (
                                      <span className="subAmount">
                                        ({convertNumber(subProj.mainE3)})
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {convertNumber(subProj.e4)} <br />
                                    {subProj.mainE4 != 0 && (
                                      <span className="subAmount">
                                        ({convertNumber(subProj.mainE4)})
                                      </span>
                                    )}
                                  </td>
                                  {bigTable && (
                                    <>
                                      <td>
                                        {convertNumber(subProj.e5)} <br />
                                        {subProj.mainE5 != 0 && (
                                          <span className="subAmount">
                                            ({convertNumber(subProj.mainE5)})
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {convertNumber(subProj.e6)} <br />
                                        {subProj.mainE6 != 0 && (
                                          <span className="subAmount">
                                            ({convertNumber(subProj.mainE6)})
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {convertNumber(subProj.e7)} <br />
                                        {subProj.mainE7 != 0 && (
                                          <span className="subAmount">
                                            ({convertNumber(subProj.mainE7)})
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {convertNumber(subProj.e8)} <br />
                                        {subProj.mainE8 != 0 && (
                                          <span className="subAmount">
                                            ({convertNumber(subProj.mainE8)})
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {convertNumber(subProj.e9)} <br />
                                        {subProj.mainE9 != 0 && (
                                          <span className="subAmount">
                                            ({convertNumber(subProj.mainE9)})
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        {convertNumber(subProj.e10)} <br />
                                        {subProj.mainE10 != 0 && (
                                          <span className="subAmount">
                                            ({convertNumber(subProj.mainE10)})
                                          </span>
                                        )}
                                      </td>
                                    </>
                                  )}
                                  <td
                                    onClick={() =>
                                      setActiveSubSub((prev) => {
                                        if (prev.includes(subProj.pnr)) {
                                          // Fjern prosjektet hvis det allerede er i listen
                                          return prev.filter(
                                            (name) => name !== subProj.pnr
                                          );
                                        } else {
                                          // Legg til prosjektet hvis det ikke er i listen
                                          return [...prev, subProj.pnr];
                                        }
                                      })
                                    }
                                  >
                                    <>
                                      {pinList.find(
                                        (item) => item.pnr === subProj.pnr
                                      ) ? (
                                        <i
                                          className="bi bi-pin-angle-fill"
                                          onClick={() =>
                                            setPinList((prev) =>
                                              prev.filter(
                                                (item) =>
                                                  item.pnr !== subProj.pnr
                                              )
                                            )
                                          }
                                        />
                                      ) : (
                                        <i
                                          className="bi bi-pin-angle"
                                          onClick={() =>
                                            setPinList((prev) => [
                                              ...prev,
                                              {
                                                pnr: subProj.pnr,
                                                parentId: index,
                                                subId: subIndex,
                                                subsubId: null,
                                              },
                                            ])
                                          }
                                        />
                                      )}
                                      <br />

                                      <ProjectHistory project={subProj.pnr}>
                                        <i
                                          className="bi bi-chat-quote"
                                          aria-expanded="false"
                                        />
                                      </ProjectHistory>
                                      {parseInt(subProj.hasReported) ? (
                                        <i
                                          style={{ color: "green" }}
                                          className="bi bi-circle-fill"
                                        />
                                      ) : (
                                        <i
                                          style={{ color: "orange" }}
                                          className="bi bi-circle-fill"
                                        />
                                      )}
                                    </>
                                  </td>
                                </tr>
                                {subProj.subData.map(
                                  (subsubProj, subsubIndex) => {
                                    return (
                                      <tr
                                        key={subsubIndex}
                                        className={`
                                          ${
                                            pinList.find(
                                              (item) =>
                                                item.pnr === subsubProj.pnr
                                            )
                                              ? `subsubProject 
                                            ${
                                              filteredTypes.includes(
                                                proj.name
                                              ) && "d-none"
                                            }
                                            pinned pinCount${
                                              pinList.find(
                                                (item) =>
                                                  item.pnr === subsubProj.pnr
                                              )?.count
                                            }`
                                              : `subsubProject
                                            ${
                                              filteredTypes.includes(
                                                proj.name
                                              ) && "d-none"
                                            }
                                            enhet-${subsubProj.enhet}`
                                          }
                                            ${
                                              filteredPL.length > 0 &&
                                              !filteredPL.includes(
                                                subsubProj.pl
                                              ) &&
                                              "d-none"
                                            }
                                        `}
                                        style={
                                          activeSub.includes(proj.name) &&
                                          activeSubSub.includes(subProj.pnr)
                                            ? {
                                                display: "table-row",
                                                height: "110px",
                                                top: pinList.find(
                                                  (prev) =>
                                                    prev.pnr === subsubProj.pnr
                                                )
                                                  ? pinHeight +
                                                    parseInt(
                                                      parseInt(
                                                        sortedPinList.findIndex(
                                                          (item) =>
                                                            item.pnr ===
                                                            subsubProj.pnr
                                                        )
                                                      ) * 105
                                                    ) +
                                                    "px"
                                                  : 0, // Sett toppen til 0 hvis elementet ikke er festet
                                              }
                                            : { display: "none" }
                                        }
                                      >
                                        <td colSpan={2}>
                                          <div
                                            className="d-flex flex-column"
                                            style={{ marginLeft: 45 }}
                                          >
                                            {subsubProj.pnr} -{" "}
                                            {subsubProj.pname}
                                            <span className="projType">
                                              <img
                                                alt=""
                                                src={proj.img}
                                                height={15}
                                                width={15}
                                              />{" "}
                                              {subProj.pnr} - {subProj.pname}
                                            </span>
                                          </div>
                                        </td>
                                        <td>{formatName(subsubProj.pl)}</td>
                                        <td>{convertNumber(subsubProj.e1)}</td>
                                        <td>{convertNumber(subsubProj.e2)}</td>
                                        <td>{convertNumber(subsubProj.e3)}</td>
                                        <td>{convertNumber(subsubProj.e4)}</td>
                                        {bigTable && (
                                          <>
                                            <td>
                                              {convertNumber(subsubProj.e5)}
                                            </td>
                                            <td>
                                              {convertNumber(subsubProj.e6)}
                                            </td>
                                            <td>
                                              {convertNumber(subsubProj.e7)}
                                            </td>
                                            <td>
                                              {convertNumber(subsubProj.e8)}
                                            </td>
                                            <td>
                                              {convertNumber(subsubProj.e9)}
                                            </td>
                                            <td>
                                              {convertNumber(subsubProj.e10)}
                                            </td>
                                          </>
                                        )}
                                        <td style={{ cursor: "pointer" }}>
                                          <>
                                            {pinList.find(
                                              (item) =>
                                                item.pnr === subsubProj.pnr
                                            ) ? (
                                              <i
                                                className="bi bi-pin-angle-fill"
                                                onClick={() =>
                                                  setPinList((prev) =>
                                                    prev.filter(
                                                      (item) =>
                                                        item.pnr !==
                                                        subsubProj.pnr
                                                    )
                                                  )
                                                }
                                              />
                                            ) : (
                                              <i
                                                className="bi bi-pin-angle"
                                                onClick={() =>
                                                  setPinList((prev) => [
                                                    ...prev,
                                                    {
                                                      pnr: subsubProj.pnr,
                                                      parentId: index,
                                                      subId: subIndex,
                                                      subsubId: subsubIndex,
                                                    },
                                                  ])
                                                }
                                              />
                                            )}{" "}
                                            <br />
                                            <ProjectHistory
                                              project={subsubProj.pnr}
                                            >
                                              <i
                                                className="bi bi-chat-quote"
                                                aria-expanded="false"
                                              />
                                            </ProjectHistory>
                                            {parseInt(
                                              subsubProj.hasReported
                                            ) ? (
                                              <i
                                                style={{ color: "green" }}
                                                className="bi bi-circle-fill"
                                              />
                                            ) : (
                                              <i
                                                style={{ color: "orange" }}
                                                className="bi bi-circle-fill"
                                              />
                                            )}
                                          </>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}
