import React, { useMemo } from "react";

export const FaseDisplay = ({ data, year }) => {
  const getFase = (data, year) => {
    const faser = [];

    const sortedData = data.sort(
      (a, b) => new Date(a.start) - new Date(b.start)
    );

    sortedData?.forEach((item) => {
      const start = new Date(item.start);
      const end = new Date(item.slutt);
      const yearStart = new Date(`${year}-01-01`);
      const yearEnd = new Date(`${year}-12-31`);

      // Check if the phase overlaps with the year
      if (start <= yearEnd && end >= yearStart) {
        const phaseStart = start < yearStart ? yearStart : start;
        const phaseEnd = end > yearEnd ? yearEnd : end;

        // Calculate the gap from the start of the year or after the last phase
        const previousEnd =
          faser.length > 0 ? faser[faser.length - 1].end : yearStart;
        const gapDuration = (phaseStart - previousEnd) / (1000 * 60 * 60 * 24); // Dager i gapet

        if (gapDuration > 0) {
          const gapWidth = (gapDuration / 365) * 100; // Prosentandel av året
          faser.push({
            fase: "dummy",
            width: gapWidth,
            start: previousEnd,
            end: phaseStart,
          });
        }

        const totalDuration = (end - start) / (1000 * 60 * 60 * 24); // Total dager
        const duration = (phaseEnd - phaseStart) / (1000 * 60 * 60 * 24); // Dager i dette året

        if (duration > 0) {
          const width = (duration / 365) * 100; // Prosentandel av året
          faser.push({
            fase: item.phase,
            width,
            start: phaseStart,
            end: phaseEnd,
          });
        }
      }
    });

    return faser;
  };

  const faser = useMemo(() => getFase(data, year), [data, year]);

  const TimelineForYear = ({ year }) => {
    const getColor = (fase) => {
      switch (fase) {
        case "skisse/forprosjekt":
          return "red";
        case "detaljprosjektering":
          return "yellow";
        case "utførelse":
          return "blue";
        case "dummy":
          return "transparent";
        default:
          return "grey";
      }
    };

    return (
      <div style={{ display: "flex", width: "100%" }}>
        {faser?.map((item, index) => (
          <div
            key={index}
            style={{
              width: `${item.width}%`,
              height: 5,
              backgroundColor: getColor(item.fase),
            }}
          />
        ))}
      </div>
    );
  };

  return <TimelineForYear year={year} />;
};
