// utils/cookieUtils.js

import Cookies from "universal-cookie";

const cookies = new Cookies();

// Funksjon for å sette en cookie
export const setCookie = (name, value, options = {}) => {
  cookies.set(name, value, options);
};

// Funksjon for å hente en cookie
export const getCookie = (name) => {
  return cookies.get(name);
};

// Funksjon for å fjerne en cookie
export const removeCookie = (name) => {
  cookies.remove(name);
};
