import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function SubProjReportStatus({
  children,
  data,
  style,
  className,
  reported,
}) {
  const [show, setShow] = useState(false);
  const _Child = () => {
    return (
      <div className={className} style={style} onClick={() => setShow(true)}>
        {children}
      </div>
    );
  };

  return (
    <>
      <_Child />
      <Modal size="xl" scrollable show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {reported
              ? "Underprosjekter som har rapportert"
              : "Underprosjekter som ikke har rapportert"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Prosjektnr</th>
                <th>Prosjektnavn</th>
                {reported && <th>Rapport</th>}
              </tr>
            </thead>
            <tbody>
              {data?.map((data, key) => {
                return (
                  <tr key={key}>
                    <td>{data.pnr}</td>
                    <td>{data.pname}</td>
                    {reported && (
                      <td>
                        <Link
                          to={"../reports/" + data.pnr}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Les rapport
                        </Link>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}
