import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";

import { Link } from "react-router-dom";

export default function Admin() {
  const [errors, setErrors] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    const getErrors = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/admin/errors.php",
          { credentials: "include" }
        );
        const data = await res.json();
        setErrors(data);
      } catch (err) {
        return;
      }
    };
    const getTableData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/admin/overview.php?tableData",
          { credentials: "include" }
        );
        const data = await res.json();
        setTableData(data);
      } catch (err) {
        return;
      }
    };
    const getReportData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/admin/overview.php?reportData",
          { credentials: "include" }
        );
        const data = await res.json();
        setReportData(data);
      } catch (err) {
        return;
      }
    };
    getErrors();
    getTableData();
    getReportData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-5 col-lg-5 col-md-8 my-2">
          <div className="mb-4">
            <h5>Prosjekt</h5>
            <div
              className="rounded table-responsive"
              style={{ borderRadius: 25, backgroundColor: "white" }}
            >
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Ant. prosjekter</th>
                    <th>Versjon</th>
                    <th>Årstall</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((tData, tIndex) => {
                    return (
                      <tr key={tIndex}>
                        <td>
                          <img src={tData.img} height={25} />
                          {tData.name}
                        </td>
                        <td>{tData.numProjects}</td>
                        <td>{tData.version}</td>
                        <td>{tData.year}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="mb-4">
            <h5>Rapportering</h5>
            <div>
              {reportData?.map((report, rKey) => {
                return (
                  <div key={rKey} className="mb-2">
                    <div
                      className="d-flex flex-row flex-wrap rounded shadow-lg"
                      style={{ backgroundColor: "white" }}
                    >
                      {/** Name */}
                      <div
                        className="d-flex flex-grow-1 align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#444",
                        }}
                      >
                        <div
                          className="p-2"
                          style={{
                            color: "white",
                            maxWidth: "145px",
                            width: "145px",
                          }}
                        >
                          <div className="justify-content-center d-flex">
                            <img src={report.img} height={25} />
                          </div>
                          <div className="justify-content-center d-flex">
                            {report.name}
                          </div>
                        </div>
                      </div>
                      {/** Report Info */}
                      <div
                        className="d-flex flex-grow-1 flex-wrap justify-content-around"
                        style={
                          parseInt(report.reportOpen)
                            ? { minWidth: "75%" }
                            : {
                                minWidth: "75%",
                                filter: "blur(1px)",
                                pointerEvents: "none",
                              }
                        }
                      >
                        <div
                          className="d-flex justify-content-center flex-column p-2"
                          style={{ backgroundColor: "" }}
                        >
                          <Link
                            to={
                              "project/notreported/" +
                              report.id +
                              "/" +
                              report.name
                            }
                            className="text-decoration-none"
                          >
                            <div className="justify-content-center d-flex fw-bold">
                              {parseInt(report.numProjects) -
                                parseInt(report.reportedProj) -
                                parseInt(report.acceptedProj)}
                            </div>
                            <div className="justify-content-center d-flex text-dark">
                              <i className="bi bi-hourglass-bottom" />
                              &nbsp;
                              <span>Ikke rapportert</span>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="d-flex justify-content-center flex-column p-2"
                          style={{ backgroundColor: "" }}
                        >
                          <Link
                            to={"project/reports/" + report.id}
                            className="text-decoration-none"
                          >
                            <div className="justify-content-center d-flex fw-bold">
                              {report.reportedProj}
                            </div>
                            <div className="justify-content-center d-flex text-primary">
                              <i className="bi bi-check-circle" />
                              &nbsp;
                              <span>Rapportert</span>
                            </div>
                          </Link>
                        </div>
                        <div
                          className="d-flex justify-content-center flex-column p-2"
                          style={{ backgroundColor: "" }}
                        >
                          <Link
                            to={
                              "project/okreported/" +
                              report.id +
                              "/" +
                              report.name
                            }
                            className="text-decoration-none"
                          >
                            <div className="justify-content-center d-flex fw-bold">
                              {report.acceptedProj}
                            </div>
                            <div className="justify-content-center d-flex text-success">
                              <i className="bi bi-check-circle-fill" />
                              &nbsp;
                              <span>Godkjent</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 my-2">
          {errors.yearErr && (
            <>
              <h5>Informasjon</h5>
              <div className="content shadow">
                <div className="admErrors d-flex justify-content-between align-items-center">
                  <div className="w-100">
                    <h6>Prosjekttyper med forskjellig årstall</h6>
                    <span>Alle prosjekttyper må være i samme regnskapsår</span>
                  </div>
                  <div className="icon">
                    <i
                      className="bi bi-exclamation-diamond"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
                <div className="adm-submit">
                  <Link to={"./project/types"}>Løs problemet</Link>
                </div>
                <hr />
              </div>
            </>
          )}
          {errors.plMissing && (
            <>
              <h5>Informasjon</h5>
              <div className="content shadow">
                <div className="admErrors d-flex justify-content-between align-items-center">
                  <div className="w-100">
                    <h6>Prosjekter mangler prosjektledere</h6>
                    <span>
                      {errors.plMissing.length} prosjekter mangler prosjektleder
                    </span>
                  </div>
                  <div className="icon">
                    <i className="bi bi-exclamation-diamond" />
                  </div>
                </div>
                <div className="adm-submit">
                  <Link to={"./project/pl"}>Løs problemet</Link>
                </div>
                <hr />
              </div>
            </>
          )}
        </div>
        <div className="col-xl-2 col-lg-3 col-md-4 my-2">
          <h5 className="text-center">Funksjoner</h5>
          <div className="d-flex flex-column">
            <div className="my-1">
              <Link to={"./project/new"}>
                <Button
                  variant="primary"
                  className="text-start"
                  size="sm"
                  style={{ width: "100%", position: "relative" }}
                >
                  Nytt prosjekt
                  <span className="position-absolute" style={{ right: 5 }}>
                    <i
                      style={{ color: "white" }}
                      className="bi bi-plus-circle-dotted"
                    />
                  </span>
                </Button>
              </Link>
            </div>
            <div className="my-1 w-100">
              <Link to={"./project/edit"}>
                <Button
                  variant="primary"
                  className="text-start"
                  size="sm"
                  style={{ width: "100%", position: "relative" }}
                >
                  Endre prosjekt
                  <span className="position-absolute" style={{ right: 5 }}>
                    <i style={{ color: "white" }} className="bi bi-pen" />
                  </span>
                </Button>
              </Link>
            </div>
            <div className="my-1 w-100">
              <Link to={"./project/version"}>
                <Button
                  variant="secondary"
                  className="text-start"
                  size="sm"
                  style={{ width: "100%", position: "relative" }}
                >
                  Versjoner
                  <span className="position-absolute" style={{ right: 5 }}>
                    <i className="bi bi-flag" />
                  </span>
                </Button>
              </Link>
            </div>
            <div className="my-1 w-100">
              <Link to={"./project/types"}>
                <Button
                  variant="secondary"
                  className="text-start"
                  size="sm"
                  style={{ width: "100%", position: "relative" }}
                >
                  Typer
                  <span className="position-absolute" style={{ right: 5 }}>
                    <i className="bi bi-fonts" />
                  </span>
                </Button>
              </Link>
            </div>
            <div className="my-1 w-100 d-none">
              <Link to={"./project/reports"}>
                <Button
                  variant="secondary"
                  className="text-start"
                  size="sm"
                  style={{ width: "100%", position: "relative" }}
                >
                  Rapportering
                  <span className="position-absolute" style={{ right: 5 }}>
                    <i className="bi bi-fonts" />
                  </span>
                </Button>
              </Link>
            </div>
            <div className="my-1 w-100">
              <Link to={"./project/pl"}>
                <Button
                  variant="warning"
                  className="text-start"
                  size="sm"
                  style={{ width: "100%", position: "relative" }}
                >
                  Prosjektledere
                  <span className="position-absolute" style={{ right: 5 }}>
                    <i className="bi bi-person-add" />
                  </span>
                </Button>
              </Link>
            </div>
            <hr />
            <div className="my-1 w-100">
              <Link to={"./users"}>
                <Button
                  variant="danger"
                  className="text-start"
                  size="sm"
                  style={{ width: "100%", position: "relative" }}
                >
                  Brukere
                  <span className="position-absolute" style={{ right: 5 }}>
                    <i className="bi bi-person-add" />
                  </span>
                </Button>
              </Link>
              <Link to={"./import/forbruk"}>
                <Button
                  variant="info"
                  className="text-start mt-2"
                  size="sm"
                  style={{ width: "100%", position: "relative" }}
                >
                  Importer forbruk
                  <span className="position-absolute" style={{ right: 5 }}>
                    <i className="bi bi-card-text" />
                  </span>
                </Button>
              </Link>
              <Link to={"./logs"}>
                <Button
                  variant="warning"
                  className="text-start mt-2"
                  size="sm"
                  style={{ width: "100%", position: "relative" }}
                >
                  Logg
                  <span className="position-absolute" style={{ right: 5 }}>
                    <i className="bi bi-card-text" />
                  </span>
                </Button>
              </Link>
            </div>
            <div className="my-1 w-100">
              <Link to={"./access"}>
                <Button
                  variant="danger"
                  className="text-start d-none"
                  size="sm"
                  style={{ width: "100%", position: "relative" }}
                >
                  Tilganger
                  <span className="position-absolute" style={{ right: 5 }}>
                    <i className="bi bi-person" />
                  </span>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
