import React, { useEffect, useState } from "react";
import { Alert, Breadcrumb, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

export default function AcceptedReports() {
  const params = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await fetch(
          "https://prosjekt.tkva.no/api/admin/reports.php?okReported=" +
            params.type_id,
          { credentials: "include" }
        );
        const data = await res.json();
        setData(data);
      } catch (err) {
        return;
      }
    };
    getData();
  }, []);

  const revokeAccept = async (pnr) => {
    return;
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link
            to={
              "../admin/project/okreported/" +
              params.type_id +
              "/" +
              params.name
            }
          >
            Godkjente prosjekter
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Alert variant="success" style={{ maxWidth: "1200px" }}>
        Prosjekter som er godkjent for {params.name}
      </Alert>
      <div style={{ maxHeight: "85%", overflowY: "auto", maxWidth: "1200px" }}>
        <ol className="list-group me-2">
          {data?.map((d, k) => {
            return (
              <li key={k} className="list-group-item">
                <div className="d-flex justify-content-between">
                  <span>
                    {d.pnr} - {d.pname} <br />
                    {d.pl}
                  </span>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    </>
  );
}
