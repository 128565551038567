import React, { useEffect, useState } from "react";
import { Breadcrumb, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

export default function UserAccess() {
  const params = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/userAccess.php?userid=" + params.id,
        { credentials: "include" }
      );
      const data = await res.json();
      setData(data);
    } catch (err) {
      return;
    }
  };

  const removeRole = async (role) => {
    const postData = {
      userId: params.id,
      role: role,
      action: "REMOVE_ROLE",
    };

    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/userAccess.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        getData();
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
  };

  const addRole = async () => {
    const role = prompt("Velg tilgang");
    const detail = prompt("Velg underkategori");

    const postData = {
      userId: params.id,
      role: role,
      detail: detail,
      action: "ADD_ROLE",
    };

    try {
      const res = await fetch(
        "https://prosjekt.tkva.no/api/admin/userAccess.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
          credentials: "include",
        }
      );
      if (!res.ok) {
        alert("Noe gikk galt.. dessverre");
      } else {
        getData();
      }
    } catch (err) {
      alert("Noe gikk galt: " + err.message);
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="../admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <Link to="../admin/users">Brukere</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-sm">
          <h4>Brukerinfo</h4>
          <hr />
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Navn
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleFormControlInput1"
              disabled
              placeholder={data?.userData?.name}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              E-post
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleFormControlInput1"
              disabled
              placeholder={data?.userData?.email}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Sist innlogget
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleFormControlInput1"
              disabled
              placeholder={data?.userData?.lastLogon}
            />
          </div>
        </div>
        <div className="col-sm">
          <h4>Tilhørighet</h4>
          <hr />
          <ul className="list-group">
            {data?.types?.map((type, tKey) => {
              return (
                <li key={tKey} className="list-group-item">
                  {type?.name}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-sm">
          <div className="d-flex justify-content-between">
            <h4>Roller</h4>
            <Button size="sm" onClick={() => addRole()}>
              Legg til rolle
            </Button>
          </div>
          <hr />
          <ul className="list-group">
            {data?.roles?.map((role, tKey) => {
              return (
                <li
                  key={tKey}
                  className="list-group-item  d-flex justify-content-between align-items-start"
                >
                  <>
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{role?.role}</div>
                      {role?.description}
                    </div>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => removeRole(role.role)}
                    >
                      <i className="bi bi-x-octagon" style={{ color: "red" }} />{" "}
                      Fjern
                    </span>
                  </>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
